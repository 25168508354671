import { Routes, Route } from 'react-router-dom';
import axios from 'axios';
import Header from './components/header/Header';
import HomePage from './components/HomePage/HomePage';
import Footer from './components/Footer/Footer';
import TipstersAllPage from './components/TipstersAllPage/TipstersAllPage';
import BlogPage from './components/Blog/BlogPage';
import OwnBlog from './components/OwnBlog/OwnBlog';
import NewPickPage from './components/NewPick/NewPickPage';
import NewPickSavedPage from './components/NewPick/newPickSavedPage';
import MyTipstersPage from './components/MyTipsters/MyTipstersPage';
import SettingsPage from './components/SettingsPage/SettingsPage';
import WalletPage from './components/WalletPage/WalletPage';
import SellerAdminPage from './components/SellerAdminPage/SellerAdminPage';
import BuyerAdminPage from './components/BuyerAdmin/BuyerAdminPage';
// import ConfrimCodeModal from './components/ConfrimCode/ConfrimCodeModal';
// import ForgotPasswordModal from './components/ForgotPassword/ForgotPasswordModal';
// import ChangePasswordModal from './components/ChangePassword/ChangePasswordModal';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import PrivacyPage from './components/contants/PrivacyPage';
import TermAndConPage from './components/contants/TermAndConPage';
import PageNotFound from './components/notFound/PageNotFound';

function App() {

  const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)

  return (
    <div className="App">   
       
        <Header></Header>
        <Routes>
          
          <Route exact path="/" element={<HomePage />} />
          <Route path="/my-tipsters" element={<MyTipstersPage />} /> 
          <Route path="/tipsters" element={<TipstersAllPage />} />
          <Route path="/pravicy&policy" element={<PrivacyPage />} />
          <Route path="/termAndConPage" element={<TermAndConPage/>} />
          <Route path="/*" element={<PageNotFound/>} />

           {data && 
           <>
             <Route path="/blog" element={<BlogPage />} />
          <Route path="/my-blog" element={<OwnBlog />} />
          <Route path="/new-pick" element={<NewPickPage />} />
          <Route path="/new-pick-created" element={<NewPickSavedPage />} />
          
          <Route path="/settings" element={<SettingsPage />} />
          <Route path="/wallet" element={<WalletPage />} />
          <Route path="/seller-admin" element={<SellerAdminPage />} />
          <Route path="/buyer-admin" element={<BuyerAdminPage />} />
           </>
           }
        
          
        </Routes> 
        <Footer></Footer>
        <ToastContainer/>
    </div>
  );
}

export default App;
