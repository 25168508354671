import React from 'react'
import DepositModal from '../Deposit/DepositModal'
import ConfirmationPurchaseModal from '../ConfirmationPurchase/ConfirmationPurchaseModal'

export default function MyTipstersPage() {


  


  return (
    <>
        <section className="tw-all-tip-section">
            <div className="container">
                <div className="tw-filter-content">
                    <div className="tw-filter-input">
                        <label className="form-label">FILTER BY NUMBER OF PICKS:</label>
                        <select defaultValue={''} className="form-select" aria-label="Default select example">
                            <option disabled value=""></option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div className="tw-filter-input">
                        <label className="form-label">ORDER BY:</label>
                        <select defaultValue={''} className="form-select" aria-label="Default select example">
                            <option disabled value=""></option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>

                <ul className="nav nav-tabs" id="tipstersTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all"
                            type="button" role="tab" aria-controls="all" aria-selected="true">ALL </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="myTipsters-tab" data-bs-toggle="tab" data-bs-target="#myTipsters"
                            type="button" role="tab" aria-controls="myTipsters" aria-selected="false">MY
                            TIPSTERS</button>
                    </li>
                </ul>
                <div className="tab-content" id="tipstersTabContent">
                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                        <div className="tw-all-tip-content">
                            <div className="tw-all-tip-header">
                                <h3 className="tw-all-tip-header-title">ALL TIPSTERS</h3>
                                <span className="tw-all-tip-icon">
                                    <img src="assets/images/reload-icon.png" alt="reload-icon" className="img-fluid" />
                                </span>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img1.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">Mr.Stanley</h3>
                                    <a href="/" className="tw-tipsters-subdomain">MrStanley.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2012</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">1345</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+657</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+33%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">15725</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOW</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#depositeModal">
                                        <span>
                                            <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                                        </span>
                                        <span>55$/MONTH</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">OnlyElite</h3>
                                    <a href="/" className="tw-tipsters-subdomain">OnlyElite.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2017</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">785</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+520</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+17%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">5230</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                        data-bs-target="#unfollowModal">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOWING</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#depositeModal">
                                        <span>
                                            <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                                        </span>
                                        <span>55$/MONTH</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img3.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">WinnerOU</h3>
                                    <a href="/" className="tw-tipsters-subdomain">WinnerOU.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2015</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2574</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+1578</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+24%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">9735</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOW</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#depositeModal">
                                        <span>
                                            <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                                        </span>
                                        <span>55$/MONTH</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">OnlyElite</h3>
                                    <a href="/" className="tw-tipsters-subdomain">OnlyElite.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2017</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">785</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+520</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+17%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">5230</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                        data-bs-target="#unfollowModal">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOWING</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary" data-bs-toggle="modal"
                                        data-bs-target="#confirmPurchaseModal">
                                        <span>
                                            <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                                        </span>
                                        <span>55$/MONTH</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="myTipsters" role="tabpanel" aria-labelledby="myTipsters-tab">
                        <div className="tw-all-tip-content">
                            <div className="tw-all-tip-header">
                                <h3 className="tw-all-tip-header-title">MY TIPSTERS</h3>
                                <span className="tw-all-tip-icon">
                                    <img src="assets/images/reload-icon.png" alt="reload-icon" className="img-fluid" />
                                </span>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img1.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">Mr.Stanley</h3>
                                    <a href="/" className="tw-tipsters-subdomain">MrStanley.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2012</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">1345</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+657</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+33%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">15725</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                        data-bs-target="#unfollowModal">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOWING</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary">
                                        <span>
                                            <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                                        </span>
                                        <span>55$/MONTH</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">OnlyElite</h3>
                                    <a href="/" className="tw-tipsters-subdomain">OnlyElite.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2017</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">785</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+520</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+17%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">5230</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                        data-bs-target="#unfollowModal">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOWING</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary">
                                        <span>SUBSCRIBED</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img3.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">WinnerOU</h3>
                                    <a href="/" className="tw-tipsters-subdomain">WinnerOU.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2015</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2574</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+1578</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+24%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">9735</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                        data-bs-target="#unfollowModal">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOWING</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary">
                                        <span>SUBSCRIBED</span>
                                    </button>
                                </div>
                            </div>
                            <div className="tw-tipsters-card">
                                <div className="tw-tipsters-profile">
                                    <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img1"
                                        className="img-fluid" />
                                </div>
                                <div className="tw-tipsters-details">
                                    <h3 className="tw-tipsters-title">OnlyElite</h3>
                                    <a href="/" className="tw-tipsters-subdomain">OnlyElite.webname.com</a>
                                    <p className="tw-tipsters-country">
                                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                                            className="img-fluid" />
                                    </p>
                                </div>
                                <div className="tw-tipsters-info">
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">2017</h4>
                                        <label className="tw-tipsters-info-label">Since</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">785</h4>
                                        <label className="tw-tipsters-info-label">Picks</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+520</h4>
                                        <label className="tw-tipsters-info-label">Profit</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count text-success">+17%</h4>
                                        <label className="tw-tipsters-info-label">Yield</label>
                                    </div>
                                    <div className="tw-tipsters-info-item">
                                        <h4 className="tw-tipsters-info-count">5230</h4>
                                        <label className="tw-tipsters-info-label">Followers</label>
                                    </div>
                                </div>
                                <div className="tw-tipsters-action">
                                    <button className="btn btn-sm btn-outline-primary" data-bs-toggle="modal"
                                        data-bs-target="#unfollowModal">
                                        <span>
                                            <img src="assets/images/follow-icon.png" alt="follow-icon"
                                                className="img-fluid" />
                                        </span>
                                        <span>FOLLOWING</span>
                                    </button>
                                    <button className="btn btn-sm btn-primary">
                                        <span>
                                            <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                                        </span>
                                        <span>55$/MONTH</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section> 

        <DepositModal></DepositModal>
        <ConfirmationPurchaseModal></ConfirmationPurchaseModal>
    </>
  )
}
