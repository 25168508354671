import React from 'react'
import BuyerSubscriptionsCards from './BuyerSubscriptionsCards'

export default function BuyerSubscriptions() {
  return (
    <>
        <div className="tw-subscriptions-filter">
            <div className="tw-seller-subscribers-content">
                <form className="row g-3">
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Filter by status</label>
                        <select className="form-select" defaultValue="">
                            <option value=""></option>
                            <option vaslue="">1</option>
                            <option vaslue="">2</option>
                            <option vaslue="">3</option>
                        </select>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Filter by subscription</label>
                        <select className="form-select" defaultValue="">
                            <option value=""></option>
                            <option vaslue="">1</option>
                            <option vaslue="">2</option>
                        </select>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Order by</label>
                        <select className="form-select" defaultValue="">
                            <option value="">Plan start</option>
                            <option vaslue="">1</option>
                        </select>
                    </div>
                </form>
            </div>
        </div>
        <div className="tw-all-tip-content">
            <BuyerSubscriptionsCards></BuyerSubscriptionsCards>
        </div> 
    </>
  )
}
