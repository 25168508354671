import React, { useState } from 'react'
import $ from "jquery";
import { toast } from 'react-toastify';
export default function ConfrimCodeModal() {
    // console.log(response);

    const email =  localStorage.getItem('user_email') 
     const  verifyEmail = JSON.parse(email)
   
      
    const [otpValue , setOtpValue] = useState('')
    const [response , setResponse] = useState('')
    
    async function otpVerification (){
               
        
        if (otpValue === "") {
        
         toast.error("enter your OTP.");
        } else {

            try {
                // calling api for conform code
                let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/verify_otp", {
            
            method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json"
                },
                body:JSON.stringify({email:verifyEmail, otp:otpValue})
            });
            result = await result.json();
            setResponse(result.status);

            if  ( result.status === "1") {
              
                    toast.success('OTP has been verifield')
                    $('#forgotPasswordModal').modal("hide");
                    $('#ChangePasswordModal').modal("show");
            
             
                setOtpValue("");
                
            } else {
                toast.error(result.msg?result.msg:"something went wrong");
                
            }
            } catch (error) {
                toast.error(error.message)
            }
        }
    }

  return (
    <>
        {/* <!-- Confrim Code Modal Start --> */}
        <div className="modal fade" id="confrimCodeModal" tabIndex="-1" aria-labelledby="confrimCodeModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="confrimCodeModalLabel">FORGOT PASSWORD</h5>
                    </div>
                    <div className="modal-body">
                        <div className="tw-login-register-content">
                            <div className="tw-login-register-form">
                                <p className="tw-restore-password-text">A recovery code was sent to your email</p>
                                <div className="tw-login-register-input">
                                    <label className="form-label">Enter recovery code:</label>
                                    <input type="text" value ={otpValue} onChange={(e)=>setOtpValue(e.target.value)} className="form-control" />
                                </div>
                            </div>
                            
                            
                                <div className="tw-login-register-submit-btn">
                                    <button onClick={otpVerification} type="button" className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close"
                                        data-bs-toggle="modal" data-bs-target="#changePasswordModal">CONFIRM</button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Confrim Code Modal End -->  */}
    </>
  )
}
