
import React,{useState,useEffect} from 'react'
import {toast} from 'react-toastify'

const TermAndConPage = () => {

    const [term, setTerm] = useState('')
    const getTermandCond = async()=>{
     try {
         // call api
         let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_terms_conditions',{
             method:"POST",
             headers:{
                 "Content-Type":"application/json",
                  "Accept":"application/json"
             },
             body:JSON.stringify()
             
 
         })
         result = await result.json()
         if(result.status === '1'){
            setTerm(result.data)
         }else{
             toast.error(result.msg? result.msg :"something went wrong..")
         }
        
     } catch (error) {
         toast.error(error.message)
     }
    }
 
    useEffect(()=>{
       getTermandCond()
    },[])
  return (
    <div className='container mt-5' >
      <h1 className='text-center'>Comapany Terms & Conditions</h1>
      <p>{term.description}</p>

    </div>
  )
}

export default TermAndConPage
