import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function ChangeServiceDescriptionModal() {

    const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)

    const [desc, setDesc] = useState([])
 
    const getServiceDesc = async()=>{
 
     try {
         
         let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_service_desc',{
             method:"POST",
             headers:{
                 "Content-Type":"application/json",
                  "Accept":"application/json"
             },
             body:JSON.stringify({user_id:data.user.id})
         })
          result = await result.json()
          if(result.status === '1'){
            setDesc(result.user)
 
          }else{
             toast.error(result.msg?result.msg:"something went wrong");
          }
 
     } catch (error) {
         toast.error(error.message)
     }
    }
 
  const inputHandler =(e)=>{
   setDesc({...desc,[e.target.name]:e.target.value})
  }

   const updateserviceDesc = async()=>{
    try {
        
       let result  = await fetch('http://nodeapi.notiondemoprojects.com:8080/update_service_desc',{
        method:"POST",
        headers:{
            "Content-Type":"application/json",
            "Accept":"application/json"
        },
        body:JSON.stringify({user_id:data.user.id,service_desc:desc.service_desc})
       })
       result = await result.json()
       if(result.status === '1'){
        toast.success("description has been changed successfully")
       }else{
        toast.error(result.msg?result.msg:"something went wrong")
       }
    } catch (error) {
        toast.error(error.message)
    }

   }
   useEffect(()=>{
    getServiceDesc()
},[setDesc])


   console.log(desc,"bnbiuhnui")
  return (
    <>
        {/* <!-- Change Service Description Modal Start --> */}
        <div className="modal fade tw-modal-md" id="changeServiceDescriptionModal" tabIndex="-1"
            aria-labelledby="changeServiceDescriptionModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="changeServiceDescriptionModalLabel">CHANGE SERVICE DESCRIPTION</h5>
                    </div>
                    <div className="modal-body">
                        <div className="fw-service-description-content">
                            <textarea name='service_desc' value={desc.service_desc} onChange ={inputHandler} className="form-control" 
                            // id="serviceDescriptionEditor"
                                placeholder="Provide information about your service..." rows="10"></textarea>
                            <div className="fw-subscription-plans-btn">
                                <button className="btn btn-danger btn-sm" data-bs-dismiss="modal"
                                    aria-label="Close">CANCEL</button>
                                <button  onClick={updateserviceDesc} className="btn btn-success btn-sm" data-bs-dismiss="modal"
                                    aria-label="Close">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Change Service Description Modal End -->  */}
    </>
  )
}
