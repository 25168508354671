import React from 'react'
import TipstersAll from './TipstersAll'

export default function TipstersAllPage() {
  return (
    <>
        {/* <!-- ALL TIPS Section Start --> */}
        <section className="tw-all-tip-section">
            <div className="container">
                <div className="tw-filter-content">
                    <div className="tw-filter-input">
                        <label className="form-label">FILTER BY NUMBER OF PICKS:</label>
                        <select defaultValue={'0'} className="form-select" aria-label="Default select example">
                            <option value="0" disabled></option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                    <div className="tw-filter-input">
                        <label className="form-label">ORDER BY:</label>
                        <select defaultValue={'0'} className="form-select" aria-label="Default select example">
                            <option value="0" disabled></option>
                            <option value="1">One</option>
                            <option value="2">Two</option>
                            <option value="3">Three</option>
                        </select>
                    </div>
                </div>
                <ul className="nav nav-tabs" id="tipstersTab" role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="all-tab" data-bs-toggle="tab" data-bs-target="#all"
                            type="button" role="tab" aria-controls="all" aria-selected="true">ALL </button>
                    </li>
                </ul>
                <div className="tab-content" id="tipstersTabContent">
                    <div className="tab-pane fade show active" id="all" role="tabpanel" aria-labelledby="all-tab">
                        <div className="tw-all-tip-content">
                            <div className="tw-all-tip-header">
                                <h3 className="tw-all-tip-header-title">ALL TIPSTERS</h3>
                                <span className="tw-all-tip-icon">
                                    <img src="assets/images/reload-icon.png" alt="reload-icon" className="img-fluid" />
                                </span>
                            </div>

                            <TipstersAll></TipstersAll>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- ALL TIPS Section End --> */}
    </>
  )
}
