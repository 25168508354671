import React from 'react'
import ChangePersonaDetails from '../ChangePersonaDetails/ChangePersonaDetails'
import ChangeServiceDescriptionModal from '../ChangeServiceDescription/ChangeServiceDescriptionModal'
import ChangeSubscriptionModal from '../ChangeSubscription/ChangeSubscriptionModal'
import SellerEarning from './SellerEarning'
import SellerFeesEaening from './SellerFeesEaening'
import SellerSetting from './SellerSetting'
import SellerSubscribers from './SellerSubscribers'

export default function SellerAdminPage() {
  return (
    <>
        {/* <!-- Seller Admin Section Start -->  */}
        <section className="tw-wallet-section">
            <div className="container">
                <div className="tw-wallet-content">
                    <div className="tw-wallet-heading">
                        <h1 className="tw-wallet-title">SELLER ADMIN</h1>
                    </div>
                    <div className="tw-wallet-content__inner">
                        <ul className="nav nav-tabs" id="sellerAdminTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="settings-tab" data-bs-toggle="tab"
                                    data-bs-target="#settings" type="button" role="tab" aria-controls="settings"
                                    aria-selected="true">SETTINGS</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="earnings-tab" data-bs-toggle="tab"
                                    data-bs-target="#earnings" type="button" role="tab" aria-controls="earnings"
                                    aria-selected="false">EARNINGS</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="subscribers-tab" data-bs-toggle="tab"
                                    data-bs-target="#subscribers" type="button" role="tab" aria-controls="subscribers"
                                    aria-selected="false">SUBSCRIBERS</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="feesEarnings-tab" data-bs-toggle="tab"
                                    data-bs-target="#feesEarnings" type="button" role="tab" aria-controls="feesEarnings"
                                    aria-selected="false">FEES AND EARNINGS</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="sellerAdminTabContent">
                            <div className="tab-pane fade show active" id="settings" role="tabpanel"
                                aria-labelledby="settings-tab">
                                <div className="tw-seller-admin-content">
                                    
                                    <SellerSetting></SellerSetting>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="earnings" role="tabpanel" aria-labelledby="earnings-tab">
                                <div className="tw-seller-admin-content">
                                    
                                    <SellerEarning></SellerEarning>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="subscribers" role="tabpanel"
                                aria-labelledby="subscribers-tab">
                                <div className="tw-seller-admin-content">
                                    
                                    <SellerSubscribers></SellerSubscribers>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="feesEarnings" role="tabpanel"
                                aria-labelledby="feesEarnings-tab">
                                <div className="tw-seller-admin-content">
                                    
                                    <SellerFeesEaening></SellerFeesEaening>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Seller Admin Section End -->  */}

        <ChangeSubscriptionModal></ChangeSubscriptionModal>

        <ChangeServiceDescriptionModal></ChangeServiceDescriptionModal>

        <ChangePersonaDetails></ChangePersonaDetails>
    </>
  )
}
