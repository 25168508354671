import React from 'react'

export default function CancelSubscriptionModal() {
  return (
    <>
        {/* <!-- Cancel Subscription Modal Start --> */}
        <div className="modal fade tw-modal-md" id="cancelSubscriptionModal" tabIndex="-1"
            aria-labelledby="cancelSubscriptionModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="cancelSubscriptionModalLabel">CANCEL SUBSCRIPTION</h5>
                    </div>
                    <div className="modal-body tw-report-modal-body tw-cancel-subscription-modal-body">
                        <div className="tw-login-register-content tw-report-form-content">
                            <p className="tw-delete-comment-subtitle">Are you sure you want to cancel the subscription to
                                “bloggername”?</p>
                            <p className="tw-delete-comment-subtitle">The amount paid it is/it is not eligible for refund.
                            </p>
                            <div className="tw-delete-comment-modal-btn">
                                <button className="btn btn-danger" data-bs-dismiss="modal"
                                    aria-label="Close">Cancel</button>
                                <button className="btn btn-success" data-bs-dismiss="modal" data-bs-toggle="modal"
                                    data-bs-target="#subscriptionCancelledModal">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Cancel Subscription Modal End --> */}

        {/* <!-- Subscription Cancelled Modal Start --> */}
        <div className="modal fade tw-modal-md" id="subscriptionCancelledModal" tabIndex="-1"
            aria-labelledby="subscriptionCancelledModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="subscriptionCancelledModalLabel">SUBSCRIPTION CANCELLED</h5>
                    </div>
                    <div className="modal-body tw-report-modal-body tw-cancel-subscription-modal-body">
                        <div className="tw-login-register-content tw-report-form-content">
                            <p className="tw-delete-comment-subtitle">You have succsessfully unsubscribed from “bloggername”
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Subscription Cancelled Modal End -->  */}
    </>
  )
}
