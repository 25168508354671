import React from 'react'

export default function OwnBlogProfileDetail() {
  return (
    <>
        <div className="tw-profile-detail-heading">
                <div className="container">
                    <div className="tw-profile-detail-heading__inner">
                        <h1 className="tw-profile-title">
                            <span className="tw-profile-name">Hi, I’m OnlyElite!</span>
                            <span>
                                <img src="assets/images/country-code-14.png" alt="country-code-14" className="img-fluid" />
                            </span>
                        </h1>
                        <div className="tw-user-profile-img">
                            <img src="assets/images/user-profile-detail-img.png" alt="user-profile-detail-img"
                                className="img-fluid" />
                        </div>
                        <div className="tw-user-profile-info">
                            <div className="tw-user-profile-info-item">
                                <h5 className="tw-user-profile-info-title">1458</h5>
                                <p className="tw-user-profile-info-label">PICKS</p>
                            </div>
                            <div className="tw-user-profile-info-item">
                                <h5 className="tw-user-profile-info-title">+273</h5>
                                <p className="tw-user-profile-info-label">PROFIT</p>
                            </div>
                            <div className="tw-user-profile-info-item">
                                <h5 className="tw-user-profile-info-title">+13%</h5>
                                <p className="tw-user-profile-info-label">YIELD</p>
                            </div>
                            <div className="tw-user-profile-info-item">
                                <h5 className="tw-user-profile-info-title">5425</h5>
                                <p className="tw-user-profile-info-label">FOLLOWERS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-profile-follow-content-area">
                <div className="container">
                    <div className="tw-profile-follow-content">
                        <button className="btn btn-sm btn-primary">
                            <span>
                                <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                            </span>
                            <span>55$/MONTH</span>
                        </button>
                        <button className="btn btn-sm btn-outline-primary">
                            <span>
                                <img src="assets/images/follow-icon.png" alt="follow-icon" className="img-fluid" />
                            </span>
                            <span>FOLLOW</span>
                        </button>
                    </div>
                </div>
            </div> 
    </>
  )
}
