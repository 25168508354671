// import React from 'react'
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom"
import { toast } from 'react-toastify';

export default function LoginModal() {
    
    const [email, setEmail]=useState("");
    const [password, setPassword]=useState("");
    const [closeData, setCloseData] = useState(false)



    const navigate=useNavigate();
    
    async function logIn (){


    
        let userData = {email, password}

        if (email  ==  "") {
            toast.error('Email is required')
        } else if (password  ==  "") {
           toast.error('Password is required')
        } else {

            
           try {
            // api calling post
            let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/login", {
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json"
                },
                body:JSON.stringify(userData)
            });
    
            result = await result.json();
               
            if  ( result.status === "1") {
                toast.success('login has been successfully')
                localStorage.setItem("user_info", JSON.stringify(result));
                setCloseData(!closeData)
                navigate("/tipsters");
        
                setEmail("");
                setPassword("");
               
            } else {
                toast.error(result.msg?result.msg:"something went wrong")
                
            }
           } catch (error) {
            toast.error(error.message)
           }


        }
        
    }




    return (
    <>
        {/* <!-- Login Modal Start --> */}
        <div className="modal fade" id="loginModal" tabIndex="-1" aria-labelledby="loginModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="loginModalLabel">LOG IN</h5>
                    </div>
                    <div className="modal-body">
                        <div className="tw-login-register-content">
                            <ul className="tw-social-login-list">
                                <li className="tw-social-login-item">
                                    <a href="/" className="tw-social-login-link">
                                        <img src="assets/images/google-icon.png" alt="google-icon" className="img-fluid" />
                                    </a>
                                </li>
                                <li className="tw-social-login-item">
                                    <a href="/" className="tw-social-login-link">
                                        <img src="assets/images/social-facebook-icon.png" alt="social-facebook-icon"
                                            className="img-fluid" />
                                    </a>
                                </li>
                                <li className="tw-social-login-item">
                                    <a href="/" className="tw-social-login-link">
                                        <img src="assets/images/apple-icon.png" alt="apple-icon" className="img-fluid" />
                                    </a>
                                </li>
                            </ul>
                            <p className="tw-login-register-or-devider">or</p>
                            <div className="tw-login-register-form">
                                <div className="tw-login-register-input">
                                    <label className="form-label">Email address</label>
                                    <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" />
                                </div>
                                <div className="tw-login-register-input">
                                    <label className="form-label">Password</label>
                                    <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" />
                                </div>
                                <div className="fw-forgot-password-content">
                                    <div className="form-check tw-term-condition-check">
                                        <input className="form-check-input" type="checkbox" id="gridCheck" />
                                        <label className="form-check-label" htmlFor="gridCheck">
                                            Remember me
                                        </label>
                                    </div>
                                    <span className="fw-forgot-password-link" data-bs-dismiss="modal" aria-label="Close"
                                        data-bs-toggle="modal" data-bs-target="#forgotPasswordModal">Forgot
                                        password?</span>
                                </div>
                            </div>
                            <div className="tw-login-register-submit-btn">
                                <button onClick={logIn} type="submit"   data-bs-dismiss={closeData &&"modal"} aria-label="Close" className="btn btn-primary">LOG IN</button>
                            </div>
                            <p className="tw-login-register-form-notes"><span>Don’t have an account?</span> <span
                                    data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal"
                                    data-bs-target="#registerModal">Sign up</span></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Login Modal End -->  */}

        
    </>
  )
}
