

import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

const PrivacyPage = () => {

    const [privacy, setPrivacy] = useState('')
   const getPrivacy = async()=>{
    try {
        // call api
        let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_privacy_policy',{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                 "Accept":"application/json"
            },
            body:JSON.stringify()
            

        })
        result = await result.json()
        if(result.status === '1'){
            setPrivacy(result.data)
        }else{
            toast.error(result.msg? result.msg :"something went wrong..")
        }
       
    } catch (error) {
        toast.error(error.message)
    }
   }

   useEffect(()=>{
    getPrivacy()
   },[])
  return (
    <div className='container mt-5'>
      <h2 className='text-center'>Company Privacy and Policy</h2>
      <p>{privacy.description}</p>

    </div>
  )
}

export default PrivacyPage
