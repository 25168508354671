
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
export default function SettingsPage() { 
   


    const userdata = localStorage.getItem('user_info')
    const data = JSON.parse(userdata)
    const [input, setInput] = useState()   
    const [userData , setUserData] = useState([])
    const [country, setCountry] = useState([]) 
  const [imageurl , setimageUrl] = useState('')
  const [curentPassValue , setCurentPassValue] = useState('')
  const [newPassValue, setNewPassValue] = useState('')
  const [ConfirmPass , setConformPass] = useState('')
  
const imageHandler = (e)=>{
    setimageUrl(e.target.files[0])

}


     async function details() {
     
        // let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/get_personal_details")
        let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/get_personal_details", {
            method:'POST',
            mode: 'cors',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            },  
            body: JSON.stringify({user_id:data.user.id}),
        });
        result = await result.json();
        console.log("result", result);
        setUserData(result)
    }

    // get All country from server
    
    async function getAllCountry() {
     

        let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/get_country", {
            method:'POST',
            mode: 'cors',
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            },  
            body: JSON.stringify({user_id:data.user.id}),
        });
        result = await result.json();
        console.log("result", result);
        setCountry(result.data)
    }
    



    useEffect(()=>{
        details()
        getAllCountry()
    },[])

       
    const inputhandler= (e)=>{
    setInput({...input , [e.target.name]:e.target.value})
    }

   
   const changePassword = async(e)=>{

    e.preventDefault()
    if(ConfirmPass !== newPassValue){
        toast.error('password doest not match?')
    } else if(newPassValue ==''){
        toast.error('password fild must be filled?')
    }else{
       
       try {
        // calling api
        let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/change_password",{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                "Accept":"application/json"
            },
            body:JSON.stringify({
                user_id:data.user.id,
                old_password:curentPassValue,
                new_password:newPassValue,
            }),

           
        })
        result = await  result.json()
      
      
        if(result.status =='1'){
            toast.success('your password has been changed successfully')
            let old= JSON.parse(localStorage.getItem('user_info'))
            old.user.password = newPassValue
            localStorage.setItem('user_info', JSON.stringify(old))
            
        }else{
            toast.error(result.msg?result.msg:"something went wrong")
        }
         
       } catch (error) {
        toast.error(error.message)
       }
    }
   }
    
    return (
        <>
            {/* <!-- Progile  Setting Section Start --> */}
            <section className="tw-setting-section">
                <div className="container">
                    <div className="tw-setting-content">
                        <div className="tw-settings-heading">
                            <h1 className="tw-settings-title">SETTINGS</h1>
                        </div>
                        <div className="tw-settings-content__inner">
                            <div className="tw-profile-settings-form">

                                <form>
                                    <div className="tw-setting-profile-pic">
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <button onClick={details} type="button" className="btn btn-link">Details</button>    
                                            </div>
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label className="form-label">Profile picture</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <div className="tw-profile-setting-content">
                                                    <div className="tw-profile-img">
                                                        {/* <img src="assets/images/profile-img.png" alt="profile-img"
                                                            className="img-fluid" />
                                                    </div> */}
                                                    <img src={imageurl? URL.createObjectURL(imageurl):"assets/images/profile-img.png"} alt="profile-img"
                                                            className="img-fluid" />
                                                    </div>
                                                    <span className="tw-profile-choose-img">
                                                        <span>Choose file</span>
                                                        <input type="file" className="form-control" 
                                                         onChange={imageHandler}
                                                        />
                                                    </span>
                                                    <span className="tw-profile-img-name">profile pic.jpg</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Display name</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <input type="text" className="form-control" value={userData.data?userData.data[0].first_name: '' } readOnly />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Country</label>
                                        </div>
                                         
                                      
                                                <div className="col col-12 col-md-8 col-lg-8">
                                            <select className="form-select" >
                                            {country.map((c)=>{
                                            return (
                                                <option key={c.CountryId} value="">{c.countryName}</option>
                                                )
                                            })}
                                            </select>
                                             
                                        </div>
                                         
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Language</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <select className="form-select" >
                                                <option value=""></option>
                                                <option value="1">1</option>
                                                <option value="2">2</option>
                                                <option value="3">3</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Odd style</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <select className="form-select" name="old_style">
                                                <option value="" ></option>
                                                <option value="Decimal">Decimal</option>
                                                <option value="American">American</option>
                                                <option value="Hong Kong">Hong Kong</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Currency</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <input type="text" value="EUR" className="form-control" readOnly />
                                        </div>
                                    </div>
                                    <div className="tw-user-email-input">
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label className="form-label">Email</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <input type="text" className="form-control" value={userData.data?userData.data[0].email: '' } readOnly />
                                                
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label tw-change-password-label">Change password</label>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Current password</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <input type="password" value={curentPassValue} onChange={(e)=>setCurentPassValue(e.target.value)} className="form-control"/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label  className="form-label">New password</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <input value={newPassValue} onChange={(e)=>setNewPassValue(e.target.value)} type="password" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Confirm new password</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <input  onChange={(e)=>setConformPass(e.target.value)} type="password" className="form-control" />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tw-profile-settings-update-btn text-center">
                                <button type="submit" onClick={changePassword} className="btn btn-primary">Update</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* <!-- Progile  Setting Section End --> */}
        </>
    )
}
