import React from 'react'
import BuyerTransactionsItems from './BuyerTransactionsItems'

export default function BuyerTransactions() {
  return (
    <>
        <div className="tw-buyer-transactions-content">
            <div className="tw-buyer-transactions-content__inner">
                <div className="tw-buyer-transactions-date">
                    <div className="row">
                        <div className="col col-12 col-md-6 col-lg-5">
                            <div className="mb-3">
                                <label htmlFor="formGroupExampleInput" className="form-label">Filter
                                    from</label>
                                <input type="date" className="form-control" />
                            </div>
                        </div>
                        <div className="col col-12 col-md-6 col-lg-5">
                            <div className="mb-3">
                                <label htmlFor="formGroupExampleInput" className="form-label">Filter
                                    to</label>
                                <input type="date" className="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">DATE</th>
                                <th scope="col">Amount</th>
                                <th scope="col">Type</th>
                            </tr>
                        </thead>
                        <tbody>
                            <BuyerTransactionsItems></BuyerTransactionsItems>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </>
  )
}
