import React from 'react'
import AllTips from './AllTips'

export default function HomePage() {
  return (
    <>
        <AllTips></AllTips> 
    </>
  )
}
