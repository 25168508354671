import React from 'react'

export default function SellerFeesEaening() {
  return (
    <>
        <div className="tw-fees-earnings-content">
            <h5>feesEarnings</h5>
            <p>By enrolling as Seller in the Webname Market, you certify that you are 18
                years of age or older and that you have read and accept the the following
                Fees and Earnings Terms.
                Webname reserves the right, at its sole discretion, to alter these terms at
                any time. It is your responsibility to check them periodically for any
                changes. Your continued use of Webname Market following such revisions will
                constitute your acceptance of the changed Fees and Earnings Terms. If you do
                not agree with the revised terms, do not continue to use Webname Market.</p>
            <h5>Earnings, market fee and other costs</h5>
            <ol>
                <li>Sellers are charged a 4.5% payment processing fee for each Buyer
                    subscription. It is shown as "Taxes" in the Monthly Statement in your
                    Seller Admin. This fee is to cover the charges paid by Blogabet to
                    different payment processors for clients’ deposits.</li>
                <li>Sellers are charged a sliding Blogabet Market fee, based on the volume
                    of the NET revenue* from subscriptions per calendar month. The higher
                    the volume, the lower fee you will be charged.</li>
            </ol>
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">Net monthly revenue (EUR)</th>
                            <th scope="col">Seller's Earning(%)</th>
                            <th scope="col">Market Fee (%)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>0-500</td>
                            <td>70,00%</td>
                            <td>30,00%</td>
                        </tr>
                        <tr>
                            <td>501-1000</td>
                            <td>80,00%</td>
                            <td>20,00%</td>
                        </tr>
                        <tr>
                            <td>1001-2000</td>
                            <td>85,00%</td>
                            <td>15,00%</td>
                        </tr>
                        <tr>
                            <td>>2001</td>
                            <td>90,00%</td>
                            <td>10,00%</td>
                        </tr>
                    </tbody>
                    <caption>*the NET revenue is calculated as total earnings, excluding the
                        costs for payment processing and refunds if any.</caption>
                </table>
            </div>
            <ol>
                <li>A minimum monthly Market fee of 10 EUR is due for every calendar month
                    when a Seller has been active and published one or more paid picks. If
                    the Seller did not have sales and earnings during the month, the 10 EUR
                    fee will be deducted automatically from his Blogabet wallet at the start
                    of the next month. In case the Seller's wallet balance becomes negative,
                    his Market account will be temporary suspended pending payment of the
                    due balance.</li>
                <li>An additional administrative fee of 5 EUR will be deducted from Sellers
                    earnings for processing each Buyer refund.</li>
                <li>In case of a Buyer account being terminated due to violation of the
                    Rules for Buyers (i.e. re-sale of picks, bot usage, credit card fraud
                    etc) any active of that account subscription will be cancelled and the
                    funds removed from the Sellers Earnings regardless of days remaining
                    and/or profit provided to that subscription.</li>
            </ol>
            <h5>Payment schedule</h5>
            <p>Seller's earnings for a calendar month will appear as DUE only when all
                30-day subscriptions from that month have either expired, are pending refund
                or have become active non-refundable. Active non-refundable subscriptions
                are those subscriptions that have received a free 30-day extension and the
                customer has not requested a refund within the 72-hour deadline.</p>
            <p>To estimate when payout will be scheduled, visit the Earnings Tab of the
                Seller Admin. Hovering over the (?) icon next to the On Hold status in the
                monthly statement, the expected DUE date will be displayed.</p>
            <p>Earnings will be credited to the Seller's Wallet within 1-2 working days
                after the monthly status turns to DUE.</p>
            <p>The amount due for payment is calculated as the total revenue received from
                subscriptions minus the market fee, payment processing taxes, refunds and
                refund fees if any.</p>
        </div> 
    </>
  )
}
