import React from 'react'
import { NavLink } from 'react-router-dom'
import ChangePasswordModal from '../ChangePassword/ChangePasswordModal'
import ConfrimCodeModal from '../ConfrimCode/ConfrimCodeModal'
import ForgotPasswordModal from '../ForgotPassword/ForgotPasswordModal'
import LoginModal from '../Login/LoginModal'
import RegidterModal from '../Register/RegidterModal'
import ReportModal from '../Report/ReportModal'

export default function Footer() {
  return (
    <>
    <RegidterModal></RegidterModal>
    <LoginModal></LoginModal>
    <ForgotPasswordModal></ForgotPasswordModal>
    <ConfrimCodeModal></ConfrimCodeModal>
    <ChangePasswordModal></ChangePasswordModal>
    <ReportModal></ReportModal>
    {/* <!-- Go To  Top Arrow Start --> */}
        <div className="tw-go-top-arrow">
            <span className="tw-go-top-arrow-icon">
                <img src="assets/images/go-to-top-arrow.png" alt="go-to-top-arrow" className="img-fluid" />
            </span>
        </div>
    {/* <!-- Go To  Top Arrow End --> */}

    {/* <!-- Site Footer Start --> */}
        <footer className="tw-site-footer">
            <div className="container">
                <div className="tw-site-footer-content">
                    <h3 className="tw-footer-logo">TIP&WIN</h3>
                    <ul className="tw-footer-social-list">
                        <li className="tw-footer-social-item">
                            <a target="_blank" href="/" className="tw-footer-social-link">
                                <img src="assets/images/instagram-icon.png" alt="instagram-icon" className="img-fluid" />
                            </a>
                        </li>
                        <li className="tw-footer-social-item">
                            <a target="_blank" href="/" className="tw-footer-social-link">
                                <img src="assets/images/facebook-icon.png" alt="facebook-icon" className="img-fluid" />
                            </a>
                        </li>
                        <li className="tw-footer-social-item">
                            <a target="_blank" href="/" className="tw-footer-social-link">
                                <img src="assets/images/twitter-icon.png" alt="twitter-icon" className="img-fluid" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div className="tw-footer-bottom-content">
                    <p className="tw-site-reserved-text">ALL RIGHTS RESERVED @2022</p>
                    <ul className="tw-terms-menu">
                        <li className="tw-terms-menu-item">
                            <NavLink exact="true" activeclassname="active" className="tw-terms-menu-link" to="/termAndConPage"> Terms&Conditions </NavLink>
                        </li>
                        <li className="tw-terms-menu-item">
                            <NavLink exact="true" activeclassname="active" className="tw-terms-menu-link" to="/pravicy&policy"> Privacy policy </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </footer>
    {/* <!-- Site Footer End -->  */}
    </>
  )
}
