import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';

export default function Header() {



    const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)
    const [wallet, setWallet] = useState('')

   const getWallet = async()=>{

    try {
        
        let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_wallet_balance',{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                 "Accept":"application/json"
            },
            body:JSON.stringify({user_id:data.user.id})
        })
         result = await result.json()
         if(result.status === '1'){
            setWallet(result.user)

         }else{
            toast.error(result.msg?result.msg:"something went wrong");
         }

    } catch (error) {
        toast.error(error.message)
    }
   }

   useEffect(()=>{
    if(data){
     getWallet()
    }
   
   },[])

    const navigate  = useNavigate()
    function logOut (){
        toast.success('user has logged out')
        localStorage.clear();
        navigate("/")
    }
  return (
    <>
        <header className="tw-site-header">
            <div className="container">
                <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid">
                        <a className="navbar-brand" href="/">
                            <img src="assets/images/logo.png" alt="logo" className="img-fluid navbar-brand-logo" />
                            <span className="navbar-brand-text">TIP&WIN</span>
                        </a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                            aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icons"></span>
                            <span className="navbar-toggler-icons"></span>
                            <span className="navbar-toggler-icons"></span>
                        </button>

                        {
                            localStorage.getItem('user_info') ?

                            <>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink exact="true" activeclassname="active" className="nav-link" to="/"> TIPS </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink exact="true" activeclassname="active" className="nav-link" to="/my-tipsters"> TIPSTERS </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink exact="true" activeclassname="active" className="nav-link" to="/new-pick"> NEW PICK </NavLink>
                                        </li>
                                    </ul>
                                </div>
                                <div className="dropdown">
                                    <span className=" dropdown-toggle" type="button" id="userDropdownMenu" data-bs-toggle="dropdown"
                                        aria-expanded="false">
                                        <img src="assets/images/user-profile-img.png" alt="user-profile-img" className="img-fluid" />
                                    </span>
                                    <ul className="dropdown-menu" aria-labelledby="userDropdownMenu">
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/my-blog"> BLOG </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/new-pick"> NEW PICK + </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/my-tipsters"> MY TIPSTERS </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/wallet"> BALANCE <span>{wallet.wallet_amount} </span> € </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/settings"> SETTINGS </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/seller-admin"> SELLER ADMIN </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" className="dropdown-item" to="/buyer-admin"> BUYER ADMIN </NavLink>
                                        </li>
                                        <li>
                                            <NavLink exact="true" onClick={logOut} className="dropdown-item" to="/"> LOG OUT </NavLink>
                                        </li>
                                    </ul>
                                </div>
                            </>
                            :
                            <>
                                <div className="collapse navbar-collapse" id="navbarNav">
                                    <ul className="navbar-nav">
                                        <li className="nav-item">
                                            <NavLink exact="true" activeclassname="active" className="nav-link" to="/"> TIPS </NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink exact="true" activeclassname="active" className="nav-link" to="tipsters"> TIPSTERS </NavLink>
                                        </li>
                                        <li className="nav-item tw-log-in-item">
                                            <button className="nav-link tw-login-btn btn btn-outline-light" type="button"
                                                data-bs-toggle="modal" data-bs-target="#loginModal">LOG IN</button>
                                        </li>
                                        <li className="nav-item">
                                            <button className="nav-link tw-login-btn btn btn-danger" type="button"
                                                data-bs-toggle="modal" data-bs-target="#registerModal">SIGN UP</button>
                                        </li>
                                    </ul>
                                </div>
                            </>
                        }
                    </div>
                </nav>
            </div>
        </header>
    </>
  )
}
