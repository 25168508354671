import React from 'react'

export default function SellerSetting() {

   
    
      

    
  return (
    <>
        <div className="tw-seller-setting-content">
            <div className="tw-seller-setting-item">
                <div className="tw-seller-setting-details">
                    <h3 className="tw-seller-setting-title">Subscription plans</h3>
                    <p className="tw-seller-setting-description">Set up your subscription plans
                        and
                        fees </p>
                </div>
                <div className="tw-seller-setting-action">
                    <button  type="button" className="btn btn-danger" data-bs-toggle="modal"
                        data-bs-target="#ChangeSubscriptionPlansModal">CHANGE</button>
                </div>
            </div>
            <div className="tw-seller-setting-item">
                <div className="tw-seller-setting-details">
                    <h3 className="tw-seller-setting-title">Service description</h3>
                    <p className="tw-seller-setting-description">Write a short description for
                        your paid service that will show in market directory and in your
                        blog.</p>
                </div>
                <div className="tw-seller-setting-action">
                    <button type="button" className="btn btn-danger" data-bs-toggle="modal"
                        data-bs-target="#changeServiceDescriptionModal">CHANGE</button>
                </div>
            </div>
            <div className="tw-seller-setting-item">
                <div className="tw-seller-setting-details">
                    <h3 className="tw-seller-setting-title">Personal details</h3>
                    <p className="tw-seller-setting-description">Fill in your personal
                        information.</p>
                </div>
                <div className="tw-seller-setting-action">
                    <button type="button" className="btn btn-danger" data-bs-toggle="modal"
                        data-bs-target="#changePersonalDetailsModal">CHANGE</button>
                </div>
            </div>
        </div>
    </>
  )
}
