// import React from 'react';
import React, {useState} from 'react';
import { useNavigate } from 'react-router-dom';
import $ from 'jquery'


import ConfrimCodeModal from '../ConfrimCode/ConfrimCodeModal'
import { toast } from 'react-toastify';


export default function ForgotPasswordModal(props) {
    const [email, setEmail]=useState("");
    const [response, setResponse]=useState("");
    // const [closeModel, setCloseModel] = useState(false)
    const navigate  = useNavigate()
    async function forgot_password (){
               
        
        if (email  ===  "") {
            toast.error("Email is required.");
        } else {

         try {
            // calling api here

            let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/forgot_password", {
            
                method:'POST',
                    headers:{
                        "Content-Type":"application/json",
                        "Accept":"application/json"
                    },
                    body:JSON.stringify({email})
                });
                result = await result.json();
                setResponse(result.status);
    
                if  ( result.status === "1") {
                    toast.success('OTP has been sent to your email')
                    localStorage.setItem("user_email", JSON.stringify(email));
                  
                    setEmail("");
                    // navigate('/conform_password')
                   
                
                } else {
                    // $('#forgotPasswordModal').modal('show')
                    toast.error(result.msg?result.msg:"something went wrong");
                    
                }
         

         } catch (error) {
            toast.error(error.message)
         }

        }
    }


   console.log("object", response)

  return (
    <>
      
        <div className="modal fade" id="forgotPasswordModal" tabIndex="-1" aria-labelledby="forgotPasswordModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="forgotPasswordModalLabel">FORGOT PASSWORD</h5>
                    </div>
                    <div className="modal-body">
                        <div className="tw-login-register-content">
                            <div className="tw-login-register-form">
                                <p className="tw-restore-password-text">We will send you a code to restore your password</p>
                                <div className="tw-login-register-input">
                                    <label className="form-label">Email address</label>
                                    <input type="email" className="form-control" required value={email} onChange={(e)=>setEmail(e.target.value)} />
                                </div>
                            </div>
                            <div className="tw-login-register-submit-btn">
                                                 
                                    <button className="btn btn-primary" data-bs-dismiss="modal" aria-label="Close"
                                         data-bs-toggle="modal" data-bs-target="#confrimCodeModal"  onClick={forgot_password}>SEND</button>:
                                        
                                                   
                                {/* data-bs-toggle="modal" data-bs-target="#confrimCodeModal"*/}
                                {/* data-bs-dismiss="modal" aria-label="Close" */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
      
        {/* <!-- Forgot Password Modal End -->  */}
    </>
  )
}
