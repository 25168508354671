import React from 'react'

export default function TipstersAll() {

    
  return (
    <>
        <div className="tw-tipsters-card">
            <div className="tw-tipsters-profile">
                <img src="assets/images/tips-profile-img1.png" alt="tips-profile-img1"
                    className="img-fluid" />
            </div>
            <div className="tw-tipsters-details">
                <h3 className="tw-tipsters-title">Mr.Stanley</h3>
                <a href='/' className="tw-tipsters-subdomain">MrStanley.webname.com</a>
                <p className="tw-tipsters-country">
                    <img src="assets/images/country-code-14.png" alt="country-code-14"
                        className="img-fluid" />
                </p>
            </div>
            <div className="tw-tipsters-info">
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">2012</h4>
                    <label className="tw-tipsters-info-label">Since</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">1345</h4>
                    <label className="tw-tipsters-info-label">Picks</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+657</h4>
                    <label className="tw-tipsters-info-label">Profit</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+33%</h4>
                    <label className="tw-tipsters-info-label">Yield</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">15725</h4>
                    <label className="tw-tipsters-info-label">Followers</label>
                </div>
            </div>
            <div className="tw-tipsters-action">
                <button className="btn btn-sm btn-outline-primary">
                    <span>
                        <img src="assets/images/follow-icon.png" alt="follow-icon"
                            className="img-fluid" />
                    </span>
                    <span>FOLLOW</span>
                </button>
                <button className="btn btn-sm btn-primary">
                    <span>
                        <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                    </span>
                    <span>55$/MONTH</span>
                </button>
            </div>
        </div>
        <div className="tw-tipsters-card">
            <div className="tw-tipsters-profile">
                <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img1"
                    className="img-fluid" />
            </div>
            <div className="tw-tipsters-details">
                <h3 className="tw-tipsters-title">OnlyElite</h3>
                <a href="/" className="tw-tipsters-subdomain">OnlyElite.webname.com</a>
                <p className="tw-tipsters-country">
                    <img src="assets/images/country-code-14.png" alt="country-code-14"
                        className="img-fluid" />
                </p>
            </div>
            <div className="tw-tipsters-info">
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">2017</h4>
                    <label className="tw-tipsters-info-label">Since</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">785</h4>
                    <label className="tw-tipsters-info-label">Picks</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+520</h4>
                    <label className="tw-tipsters-info-label">Profit</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+17%</h4>
                    <label className="tw-tipsters-info-label">Yield</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">5230</h4>
                    <label className="tw-tipsters-info-label">Followers</label>
                </div>
            </div>
            <div className="tw-tipsters-action">
                <button className="btn btn-sm btn-outline-primary">
                    <span>
                        <img src="assets/images/follow-icon.png" alt="follow-icon"
                            className="img-fluid" />
                    </span>
                    <span>FOLLOW</span>
                </button>
                <button className="btn btn-sm btn-primary">
                    <span>
                        <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                    </span>
                    <span>55$/MONTH</span>
                </button>
            </div>
        </div>
        <div className="tw-tipsters-card">
            <div className="tw-tipsters-profile">
                <img src="assets/images/tips-profile-img3.png" alt="tips-profile-img1"
                    className="img-fluid" />
            </div>
            <div className="tw-tipsters-details">
                <h3 className="tw-tipsters-title">WinnerOU</h3>
                <a href="/" className="tw-tipsters-subdomain">WinnerOU.webname.com</a>
                <p className="tw-tipsters-country">
                    <img src="assets/images/country-code-14.png" alt="country-code-14"
                        className="img-fluid" />
                </p>
            </div>
            <div className="tw-tipsters-info">
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">2015</h4>
                    <label className="tw-tipsters-info-label">Since</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">2574</h4>
                    <label className="tw-tipsters-info-label">Picks</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+1578</h4>
                    <label className="tw-tipsters-info-label">Profit</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+24%</h4>
                    <label className="tw-tipsters-info-label">Yield</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">9735</h4>
                    <label className="tw-tipsters-info-label">Followers</label>
                </div>
            </div>
            <div className="tw-tipsters-action">
                <button className="btn btn-sm btn-outline-primary">
                    <span>
                        <img src="assets/images/follow-icon.png" alt="follow-icon"
                            className="img-fluid" />
                    </span>
                    <span>FOLLOW</span>
                </button>
                <button className="btn btn-sm btn-primary">
                    <span>
                        <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                    </span>
                    <span>55$/MONTH</span>
                </button>
            </div>
        </div>
        <div className="tw-tipsters-card">
            <div className="tw-tipsters-profile">
                <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img1"
                    className="img-fluid" />
            </div>
            <div className="tw-tipsters-details">
                <h3 className="tw-tipsters-title">OnlyElite</h3>
                <a href="/" className="tw-tipsters-subdomain">OnlyElite.webname.com</a>
                <p className="tw-tipsters-country">
                    <img src="assets/images/country-code-14.png" alt="country-code-14"
                        className="img-fluid" />
                </p>
            </div>
            <div className="tw-tipsters-info">
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">2017</h4>
                    <label className="tw-tipsters-info-label">Since</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">785</h4>
                    <label className="tw-tipsters-info-label">Picks</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+520</h4>
                    <label className="tw-tipsters-info-label">Profit</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count text-danger">+17%</h4>
                    <label className="tw-tipsters-info-label">Yield</label>
                </div>
                <div className="tw-tipsters-info-item">
                    <h4 className="tw-tipsters-info-count">5230</h4>
                    <label className="tw-tipsters-info-label">Followers</label>
                </div>
            </div>
            <div className="tw-tipsters-action">
                <button className="btn btn-sm btn-outline-primary">
                    <span>
                        <img src="assets/images/follow-icon.png" alt="follow-icon"
                            className="img-fluid" />
                    </span>
                    <span>FOLLOW</span>
                </button>
                <button className="btn btn-sm btn-primary">
                    <span>
                        <img src="assets/images/cart-icon.png" alt="cart-icon" className="img-fluid" />
                    </span>
                    <span>55$/MONTH</span>
                </button>
            </div>
        </div>
    </>
  )
}
