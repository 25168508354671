import React from 'react'
import { toast } from 'react-toastify'

export default function MyCoupon({coupun,setCoupun}) {
    

   
    const removeItem = (index)=>{
    const  newCoupunList = coupun.filter((list,i)=>i !== index)
        setCoupun(newCoupunList)
        toast.success('one pick has removed')
       
    }

  return (
    <>  
        {coupun.map((c,index)=>{

            return(
                <>
      <div className="tw-my-coupon-card" key={index} >
            <div className="tw-my-coupon-card-heading" >
                <h3 className="tw-my-coupon-card-title" key={index}>Sport: {c.sport.split(",")[0]}</h3>
                <span className="tw-my-coupon-card-remove-icon" onClick={()=>removeItem(index)} >
                    <svg  width="14" height="14" viewBox="0 0 14 14" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 2L12 12M11.6552 2L2 12" stroke="white"
                            strokeWidth="4" />
                    </svg>
                </span>
            </div>
            <div className="tw-my-coupon-card-body">
                <h3 className="tw-my-coupon-card-subtitle">compition:</h3>
                <span className="tw-my-coupon-card-description">{c.competition}
                </span>
                <div className="tw-my-coupon-card-detail">
                    <label className="tw-my-coupon-card-detail-label">Event:</label>
                    <span className="tw-my-coupon-card-detail-text">{c.event}</span>
                </div>
                <div className="tw-my-coupon-card-detail">
                    <label className="tw-my-coupon-card-detail-label">Market:</label>
                    <span className="tw-my-coupon-card-detail-text">{c.market}</span>
                </div>
                <div className="tw-my-coupon-card-detail">
                    <label className="tw-my-coupon-card-detail-label">bet:</label>
                    <span className="tw-my-coupon-card-detail-text">{c.bet.split(",")[0]}</span>
                </div>
                <div className="tw-my-coupon-card-detail">
                    <label className="tw-my-coupon-card-detail-label">Bookmaker:</label>
                    <span className="tw-my-coupon-card-detail-text">{c.bookmaker.split(",")[0]}</span>
                </div>
            </div>
        </div>
       
      </>
            )
        })}
    </>
  )
}
