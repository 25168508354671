import React from 'react'

export default function DepositModal() {
  return (
    <>
        {/* <!-- Deposit Modal Start --> */}
        <div className="modal fade tw-modal-md" id="depositeModal" tabIndex="-1" aria-labelledby="depositeModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="depositeModalLabel">DEPOSIT</h5>
                    </div>
                    <div className="modal-body">
                        <div className="tw-deposit-content">
                            <p className="tw-deposit-error">Your balance is not enough. You need to deposit before
                                purchasing.</p>
                            <div className="tw-deposit-form">
                                <form>
                                    <div className="row">

                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Current balance:</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <h3 className="tw-deposit-current-balance">15€</h3>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Deposit amount (EUR):</label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <input type="number" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row tw-deposit-payment-method">
                                        <div className="col col-12 col-md-4 col-lg-4">
                                            <label className="form-label">Payment method: </label>
                                        </div>
                                        <div className="col col-12 col-md-8 col-lg-8">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="paymentMethodRadio"
                                                    id="paymentMethodRadio1" />
                                                <label className="form-check-label" htmlFor="paymentMethodRadio1">
                                                    Card
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="paymentMethodRadio"
                                                    id="paymentMethodRadio2" defaultChecked />
                                                <label className="form-check-label" htmlFor="paymentMethodRadio2">
                                                    PayPal
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="paymentMethodRadio"
                                                    id="paymentMethodRadio3" />
                                                <label className="form-check-label" htmlFor="paymentMethodRadio3">
                                                    Skrill
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tw-agree-rules-check">
                                        <div className="col-12">
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                <label className="form-check-label" htmlFor="gridCheck">
                                                    I aknowledge that I read and agree with the <a href="/">Wallet
                                                        Rules</a>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="tw-deposit-submit-btn">
                                <button type="submit" data-bs-dismiss="modal" aria-label="Close"
                                    className="btn btn-primary">Deposit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Deposit Modal End -->  */}
    </>
  )
}
