import React from 'react'

export default function SellerSubscribers() {
  return (
    <>
            <div className="tw-seller-subscribers-content">
                <form className="row g-3">
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Filter by period</label>
                        <select className="form-select" defaultValue="1">
                            <option value="1" >Last 6 months</option>
                            <option value="">Last 3 months</option>
                            <option value="">Last Year</option>
                        </select>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Filter by status</label>
                        <select className="form-select" defaultValue="1">
                            <option value="1" >Active</option>
                            <option value="">Deactive</option>
                        </select>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Filter by payment status</label>
                        <select className="form-select" defaultValue="1">
                            <option value="1" >Paid</option>
                            <option value="">UnPaid</option>
                        </select>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Filter by subscription</label>
                        <select className="form-select" defaultValue="1">
                            <option value="1" >30 Days</option>
                            <option value="">7 Days</option>
                            <option value="">Pick</option>
                        </select>
                    </div>
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Order by</label>
                        <select className="form-select" defaultValue="1">
                            <option value="1" >Plan start </option>
                            <option value="">Plan End</option>
                        </select>
                    </div>
                </form>
            </div> 
    </>
  )
}
