import React,{useState,useEffect} from 'react'
import {toast} from 'react-toastify'
export default function ChangeSubscriptionModal() {

    const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)

    const [sub , setSub] = useState([])
   
    const getSubsscriptionPlan = async()=>{
      
        try {
         
            let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_my_subcription_plan',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                     "Accept":"application/json"
                },
                body:JSON.stringify({user_id:data.user.id})
            })
             result = await result.json()
             if(result.status === '1'){
                setSub(result.data)
    
             }else{
                toast.error(result.msg?result.msg:"something went wrong");
             }
    
        } catch (error) {
            toast.error(error.message)
        }
       }

       const inputHandler =(e)=>{
        console.log(e.target.value)
        setSub({...sub, [e.target.name]:e.target.value})
    }

     const updateSubscriptionPlan = async()=>{
        try {
         
            let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/update_my_subcription_plan',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                     "Accept":"application/json"
                },
                body:JSON.stringify({user_id:sub.id, month_amount:sub.subcription_amount})
            })
             result = await result.json()
             if(result.status === '1'){
                toast.success('Subcription has been updated successfully.')
    
             }else{
                toast.error(result.msg?result.msg:"something went wrong");
             }
    
        } catch (error) {
            toast.error(error.message)
        }

     }

       useEffect(()=>{
        getSubsscriptionPlan()
       },[])

       const sevenDayPer = (sub.subcription_amount*30)/100
       const pickPer = (sub.subcription_amount*10)/100
    
       
  return (
    <>
        {/* <!-- Change Subscription Plans Modal Start --> */}
        <div className="modal fade tw-modal-md" id="ChangeSubscriptionPlansModal" tabIndex="-1"
            aria-labelledby="ChangeSubscriptionPlansModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="ChangeSubscriptionPlansModalLabel">CHANGE SUBSCRIPTION PLANS</h5>
                    </div>
                    <div className="modal-body">
                        <div className="fw-subscription-plans-content">
                            <p className="fw-subscription-plans-notes">Set the amounts you wish your subscriber to pay for
                                30 days.<br /> Allowed price range is 25€ - 1000€. 7 days plan is automatically
                                calculated.</p>
                            <div className="fw-subscription-plans-table">
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="w-50">Price</th>
                                                <th scope="col" className="w-50">%</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <div className="fw-subscription-plans-price">
                                                        <div className="row g-0">
                                                            <div className="col col-12 col-md-4 col-lg-4">
                                                                <label className="form-label">30 days</label>
                                                            </div>
                                                            <div className="col col-12 col-md-8 col-lg-8">
                                                                <div className="input-group">
                                                                    <input type="number" 
                                                                    onChange={inputHandler}
                                                                    value={sub.subcription_amount}
                                                                    name="subcription_amount"
                                                                    className="form-control" />
                                                                    <span className="input-group-text"
                                                                        id="basic-addon2">€</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="fw-subscription-plans-percent">100%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="fw-subscription-plans-price">
                                                        <div className="row g-0">
                                                            <div className="col col-12 col-md-4 col-lg-4">
                                                                <label className="form-label">7 days</label>
                                                            </div>
                                                            <div className="col col-12 col-md-8 col-lg-8">
                                                                <div className="input-group">
                                                                    <input type="number" 
                                                                     value={sevenDayPer}
                                                                    className="form-control" readOnly />
                                                                    <span className="input-group-text"
                                                                        id="basic-addon2">€</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="fw-subscription-plans-percent">30%</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <div className="fw-subscription-plans-price">
                                                        <div className="row g-0">
                                                            <div className="col col-12 col-md-4 col-lg-4">
                                                                <label className="form-label">Pick</label>
                                                            </div>
                                                            <div className="col col-12 col-md-8 col-lg-8">
                                                                <div className="input-group">
                                                                    <input type="number" 
                                                                     value={pickPer}
                                                                    className="form-control" readOnly />
                                                                    <span className="input-group-text"
                                                                        id="basic-addon2">€</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="fw-subscription-plans-percent">10%</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="fw-subscription-plans-btn">
                                <button className="btn btn-danger btn-sm" data-bs-dismiss="modal"
                                    aria-label="Close">CANCEL</button>
                                <button className="btn btn-success btn-sm" data-bs-dismiss="modal"
                                  onClick={updateSubscriptionPlan}
                                  aria-label="Close">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Change Subscription Plans Modal End -->  */}
    </>
  )
}
