import React, { useState } from 'react'
import { toast } from 'react-toastify'
// import { Default } from 'react-toastify/dist/utils'

export default function Deposit() {

    const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)
    const [input, setInput] = useState({
        amount:"",
        payment_method:"Card"
    })
 
  
    const inputHandler = (e)=>{
        setInput({...input,[e.target.name]:e.target.value})
    }

   
    const postDeposite = async(e)=>{
        e.preventDefault()
     
     try {
       
         let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/deposit_to_wallet',{
             method:"POST",
             headers:{
                 "Content-Type":"application/json",
                  "Accept":"application/json"
             },
             body:JSON.stringify({user_id:data.user.id,amount:input.amount,payment_method:input.payment_method})
         })
          result = await result.json()
          console.log(result,"result")
          if(result.status ==='1'){
          toast.success('deposit has been successfully')
 
          }else{
             toast.error(result.msg?result.msg:"something went wrong");
          }
 
     } catch (error) {
         toast.error(error.message)
     }
    }
 

  return (
    <>
      <div className="tw-deposit-form">
        <form>
            <div className="row">
                <div className="col col-12 col-md-4 col-lg-4">
                    <label className="form-label">Deposit amount (EUR):</label>
                </div>
                <div className="col col-12 col-md-8 col-lg-8">
                    <input onChange={inputHandler} name="amount" type="number" className="form-control" />
                </div>
            </div>
            <div className="row tw-deposit-payment-method">
                <div className="col col-12 col-md-4 col-lg-4">
                    <label className="form-label">Payment method: </label>
                </div>
                <div className="col col-12 col-md-8 col-lg-8">
                    <div className="form-check">
                        <input  onChange={inputHandler} className="form-check-input" type="radio"
                            // name="depositePaymentMethodRadio"
                            name='payment_method'
                            value="Card"
                            id="depositePaymentMethodRadio1" checked />
                        <label className="form-check-label"
                            htmlFor="depositePaymentMethodRadio1">
                            Card
                        </label>
                    </div>
                    <div className="form-check">
                        <input onChange={inputHandler} className="form-check-input" type="radio"
                            // name="depositePaymentMethodRadio"
                            name='payment_method'
                            value="Paypal"
                            id="depositePaymentMethodRadio2" />
                        <label className="form-check-label"
                            htmlFor="depositePaymentMethodRadio2">
                            PayPal
                        </label>
                    </div>
                    <div className="form-check">
                        <input  onChange={inputHandler} className="form-check-input" type="radio"
                            // name="depositePaymentMethodRadio"
                            name='payment_method'
                            value="Skrill"
                            id="depositePaymentMethodRadio3" />
                        <label className="form-check-label"
                            htmlFor="depositePaymentMethodRadio3">
                            Skrill
                        </label>
                    </div>
                </div>
            </div>
            <div className="tw-agree-rules-check">
                <div className="row">
                    <div className="col col-12 col-md-8 col-lg-8 ms-auto">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox"
                                id="gridCheck" />
                            <label className="form-check-label" htmlFor="gridCheck">
                                I aknowledge that I read and agree with the <a
                                    href="/">Wallet
                                    Rules</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col col-12 col-md-8 col-lg-8 ms-auto">
                    <div className="tw-wallet-deposit-btn">
                        <button onClick={postDeposite} className="btn btn-primary">Deposit</button>
                    </div>
                </div>
            </div>
        </form>
    </div>
    </>
  )
}
