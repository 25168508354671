import React from 'react'

export default function ReportModal() {
  return (
    <>
        {/* <!-- Report Modal Start --> */}
        <div className="modal fade tw-modal-md" id="reportFormModal" tabIndex="-1" aria-labelledby="reportFormModalLabel"
            aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="reportFormModalLabel">REPORT FORM</h5>
                    </div>
                    <div className="modal-body tw-report-modal-body">
                        <div className="tw-login-register-content tw-report-form-content">
                            <h6 className="tw-report-form-title">Report spam, inappropriate content or wrong graded pick.
                            </h6>
                            <p className="tw-report-form-subtitle">Provide a reason for your report, max 256 symbols
                                (mandatory!)</p>
                            <textarea rows="11" className="form-control"></textarea>
                            <div className="tw-report-modal-btn">
                                <button className="btn btn-danger">REPORT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Report Modal End -->  */}
    </>
  )
}
