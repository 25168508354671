import React from 'react'
import CancelSubscriptionModal from '../CancelSubscription/CancelSubscriptionModal'
import BuyerSubscriptions from './BuyerSubscriptions'
import BuyerTransactions from './BuyerTransactions'

export default function BuyerAdminPage() {
  return (
    <>
      {/* <!-- Progile Wallet Section Start --> */}
        <section className="tw-wallet-section">
            <div className="container">
                <div className="tw-wallet-content">
                    <div className="tw-wallet-heading">
                        <h1 className="tw-wallet-title">BUYER ADMIN</h1>
                    </div>
                    <div className="tw-wallet-content__inner">
                        <ul className="nav nav-tabs" id="buyerAdminTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="subscriptions-tab" data-bs-toggle="tab"
                                    data-bs-target="#subscriptions" type="button" role="tab"
                                    aria-controls="subscriptions" aria-selected="true">SUBSCRIPTIONS</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="transactions-tab" data-bs-toggle="tab"
                                    data-bs-target="#transactions" type="button" role="tab" aria-controls="transactions"
                                    aria-selected="false">TRANSACTIONS</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="buyerAdminTabContent">
                            <div className="tab-pane fade show active" id="subscriptions" role="tabpanel"
                                aria-labelledby="subscriptions-tab">
                                <div className="tw-buyer-admin-content">
                                    
                                  <BuyerSubscriptions></BuyerSubscriptions>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="transactions" role="tabpanel"
                                aria-labelledby="transactions-tab">
                                <div className="tw-buyer-admin-content">
                                    
                                    <BuyerTransactions></BuyerTransactions>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Progile Wallet Section End -->  */}

        <CancelSubscriptionModal></CancelSubscriptionModal>
    </>
  )
}
