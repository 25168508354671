import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import Deposit from './Deposit'
import TransactionHistory from './TransactionHistory'
import Withdraw from './Withdraw'

export default function WalletPage() {

  const oldData = localStorage.getItem('user_info')
   const data = JSON.parse(oldData)
   const [wallet, setWallet] = useState('')

   const getWallet = async()=>{

    try {
        
        let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_wallet_balance',{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                 "Accept":"application/json"
            },
            body:JSON.stringify({user_id:data.user.id})
        })
         result = await result.json()
         if(result.status === '1'){
            setWallet(result.user)

         }else{
            toast.error(result.msg?result.msg:"something went wrong");
         }

    } catch (error) {
        toast.error(error.message)
    }
   }

   useEffect(()=>{
    getWallet()
   },[])

  return (
    <>
        {/* <!-- Progile Wallet Section Start --> */}
        <section className="tw-wallet-section">
            <div className="container">
                <div className="tw-wallet-content">
                    <div className="tw-wallet-heading">
                        <h1 className="tw-wallet-title">WALLET</h1>
                        <h5 className="tw-wallet-balance">BALANCE :{wallet.wallet_amount}.00 EUR</h5>
                    </div>
                    <div className="tw-wallet-content__inner">
                        <ul className="nav nav-tabs" id="walletTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="transactionHistory-tab" data-bs-toggle="tab"
                                    data-bs-target="#transactionHistory" type="button" role="tab"
                                    aria-controls="transactionHistory" aria-selected="true">TRANSACTION HISTORY</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="deposit-tab" data-bs-toggle="tab" data-bs-target="#deposit"
                                    type="button" role="tab" aria-controls="deposit"
                                    aria-selected="false">DEPOSIT</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="withdraw-tab" data-bs-toggle="tab"
                                    data-bs-target="#withdraw" type="button" role="tab" aria-controls="withdraw"
                                    aria-selected="false">WITHDRAW</button>
                            </li>
                        </ul>
                        <div className="tab-content" id="walletTabContent">
                            <div className="tab-pane fade show active" id="transactionHistory" role="tabpanel"
                                aria-labelledby="transactionHistory-tab">
                                
                                <TransactionHistory></TransactionHistory>

                            </div>
                            <div className="tab-pane fade" id="deposit" role="tabpanel" aria-labelledby="deposit-tab">
                                <div className="tw-wallet-deposit-content">
                                    
                                    <Deposit></Deposit>

                                </div>
                            </div>
                            <div className="tab-pane fade" id="withdraw" role="tabpanel" aria-labelledby="withdraw-tab">
                                <div className="tw-wallet-deposit-content">
                                    
                                    <Withdraw></Withdraw>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Progile Wallet Section End --> */}
    </>
  )
}
