import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function TransactionHistoryItem() {

    const oldData = localStorage.getItem('user_info')
   const data = JSON.parse(oldData)

   const [history, setHistory] = useState([])

   const getTransactionHis = async()=>{

    try {
        
        let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_wallet_history',{
            method:"POST",
            headers:{
                "Content-Type":"application/json",
                 "Accept":"application/json"
            },
            body:JSON.stringify({user_id:data.user.id})
        })
         result = await result.json()
         if(result.status === '1'){
            setHistory(result.data)

         }else{
            toast.error(result.msg?result.msg:"something went wrong");
         }

    } catch (error) {
        toast.error(error.message)
    }
   }

   useEffect(()=>{
    getTransactionHis()
   },[])

  return (
    <>
      {history.map((his)=>{
            return(
        <tr>
        <td>
         
                <p className="tw-wallet-history-type">
                <label className="tw-wallet-history-label">{his.type} <span>{his.amount}
                        €</span></label>
                <span className="tw-wallet-history-description">
                    WID: 6c9413a3ee046418b70059b1b34c7038 , {his.payment_method}
                    Transaction ID: {his.tran_id}
                </span>
                <span className="tw-wallet-history-satus">{his.status}</span>
            </p>
         
            </td>
            <td>
             
             <span className="tw-wallet-history-date">{his.created} </span>
               
            </td>
            <td>
               
                    <span className="tw-wallet-history-amount">{his.amount}</span>
              
            </td>
        </tr>
        )
    })}

        {/* <tr>
            <td>
                <p className="tw-wallet-history-type">
                    <label className="tw-wallet-history-label">Market Fee</label>
                    <span className="tw-wallet-history-description">
                        WID: bc04cea4c9308aa88907a94eb9317b58
                    </span>
                    <span className="tw-wallet-history-satus">Completed</span>
                </p>
            </td>
            <td><span className="tw-wallet-history-date">29 Dec 2021, 16:34 </span>
            </td>
            <td>
                <span className="tw-wallet-history-amount text-danger">-25€</span>
            </td>
                        </tr> */}
    </>
  )
}
