import React from 'react'
import BlogAllTips from './BlogAllTips'
import BlogOwnerDetails from './BlogOwnerDetails'

export default function BlogPage() {
  return (
    <>
        {/* <!-- Blog Section Start --> */}
        <section className="tw-profile-details-section">
            <div className="tw-profile-details-section__inner">
            </div>
            
            <BlogOwnerDetails></BlogOwnerDetails>

        </section>
        {/* <!-- Blog Section End --> */}

        {/* <!-- Blog Section End --> */}
        <section className="tw-profile-section tw-view-blog-section">
            <div className="container">
                <div className="row">
                    <div className="col ocl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 ms-auto">
                        <div className="tw-profile-section__inner">
                            <div className="tw-profile-data-chart-content">
                                <div className="row">
                                    <div className="col col-12 col-md-4 col-lg-4">
                                        <div className="tw-profile-rate-card">
                                            <div className="tw-profile-rate-card-header">
                                                <h3 className="tw-profile-rate-title">WIN RATE</h3>
                                            </div>
                                            <div className="tw-profile-rate-card-body">
                                                <div className="tw-profile-win-rate-chart">
                                                    <canvas id="twProfileWinRateChart"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-4">
                                        <div className="tw-profile-rate-card">
                                            <div className="tw-profile-rate-card-header">
                                                <h3 className="tw-profile-rate-title">TOP SPORTS</h3>
                                            </div>
                                            <div className="tw-profile-rate-card-body">
                                                <div className="tw-profile-win-rate-chart">
                                                    <canvas id="twProfileWinRateChart2"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-4">
                                        <div className="tw-profile-rate-card">
                                            <div className="tw-profile-rate-card-header">
                                                <h3 className="tw-profile-rate-title">PROFIT CHART</h3>
                                            </div>
                                            <div className="tw-profile-rate-card-body">
                                                <div className="tw-profile-win-rate-chart">
                                                    <canvas id="twProfileProfiChart"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tw-all-tip-content">
                                <div className="tw-all-tip-header">
                                    <h3 className="tw-all-tip-header-title">SPORTS BETTING TIPS FEED</h3>
                                    <span className="tw-all-tip-icon">
                                        <img src="assets/images/reload-icon.png" alt="reload-icon" className="img-fluid" />
                                    </span>
                                </div>
                                
                                <BlogAllTips></BlogAllTips>

                            </div>
                        </div>
                    </div>
                    <div className="col ocl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-2">
                        <div className="tw-profile-rate-card tw-service-drescription-card">
                            <div className="tw-profile-rate-card-header">
                                <h3 className="tw-profile-rate-title">SERVICE DRESCRIPTION</h3>
                            </div>
                            <div className="tw-profile-rate-card-body">
                                <div className="tw-service-drescription">
                                    <p>At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis
                                        praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias
                                        excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui
                                        officia deserunt mollitia animi, id est laborum et dolorum fuga.<br />
                                        Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore,
                                        cum sluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                                        placeat facer possimus, omnis voluptas assumenda est, omnis dolor repellendus.
                                        Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore,
                                        cum sluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime
                                        placeat facer. Nam libero tempore, cum sluta nobis est eligendi optio cumque
                                        nihil impedit quo minus id quod maxime placeat acere</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Blog Section End -->  */}
    </>
  )
}
