import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import OwnBlogProfileDetail from './OwnBlogProfileDetail'
import OwnBlogTips from './OwnBlogTips'

export default function OwnBlog() {

    const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)
    const [desc, setDesc] = useState('')
 
    const getServiceDesc = async()=>{
 
     try {
         
         let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_service_desc',{
             method:"POST",
             headers:{
                 "Content-Type":"application/json",
                  "Accept":"application/json"
             },
             body:JSON.stringify({user_id:data.user.id})
         })
          result = await result.json()
          if(result.status === '1'){
            setDesc(result.user)
 
          }else{
             toast.error(result.msg?result.msg:"something went wrong");
          }
 
     } catch (error) {
         toast.error(error.message)
     }
    }
 
    useEffect(()=>{
        getServiceDesc()
    },[])


  return (
    <>
      {/* <!-- Blog Section Start --> */}
        <section className="tw-profile-details-section">
            <div className="tw-profile-details-section__inner">
            </div>

            <OwnBlogProfileDetail></OwnBlogProfileDetail>

        </section>
        {/* <!-- Blog Section End --> */}

        {/* <!-- Blog Section End --> */}
        <section className="tw-profile-section tw-own-profile-section">
            <div className="container">
                <div className="row">
                    <div className="col ocl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-8 ms-auto">
                        <div className="tw-profile-section__inner">
                            <div className="tw-profile-data-chart-content">
                                <div className="row">
                                    <div className="col col-12 col-md-4 col-lg-4">
                                        <div className="tw-profile-rate-card">
                                            <div className="tw-profile-rate-card-header">
                                                <h3 className="tw-profile-rate-title">WIN RATE</h3>
                                            </div>
                                            <div className="tw-profile-rate-card-body">
                                                <div className="tw-profile-win-rate-chart">
                                                    <canvas id="twProfileWinRateChart"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-4">
                                        <div className="tw-profile-rate-card">
                                            <div className="tw-profile-rate-card-header">
                                                <h3 className="tw-profile-rate-title">WIN RATE</h3>
                                            </div>
                                            <div className="tw-profile-rate-card-body">
                                                <div className="tw-profile-win-rate-chart">
                                                    <canvas id="twProfileWinRateChart2"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col col-12 col-md-4 col-lg-4">
                                        <div className="tw-profile-rate-card">
                                            <div className="tw-profile-rate-card-header">
                                                <h3 className="tw-profile-rate-title">PROFIT CHART</h3>
                                            </div>
                                            <div className="tw-profile-rate-card-body">
                                                <div className="tw-profile-win-rate-chart">
                                                    <canvas id="twProfileProfiChart"></canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="tw-all-tip-content">
                                <div className="tw-all-tip-header">
                                    <h3 className="tw-all-tip-header-title">SPORTS BETTING TIPS FEED</h3>
                                    <span className="tw-all-tip-icon">
                                        <img src="assets/images/reload-icon.png" alt="reload-icon" className="img-fluid" />
                                    </span>
                                </div>
                                
                                <OwnBlogTips></OwnBlogTips>

                            </div>
                        </div>
                    </div>
                    <div className="col ocl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-2">
                        <div className="tw-profile-rate-card tw-service-drescription-card">
                            <div className="tw-profile-rate-card-header">
                                <h3 className="tw-profile-rate-title">SERVICE DRESCRIPTION</h3>
                            </div>
                            <div className="tw-profile-rate-card-body">
                                <div className="tw-service-drescription">
                                    <p>{desc.service_desc}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Blog Section End --> */}

        {/* <!-- Delete Comment Modal Start --> */}
        <div className="modal fade tw-modal-md" id="deleteCommentModal" tabIndex="-1"
            aria-labelledby="deleteCommentModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="deleteCommentModalLabel">DELETE COMMENT</h5>
                    </div>
                    <div className="modal-body tw-report-modal-body">
                        <div className="tw-login-register-content tw-report-form-content">
                            <p className="tw-delete-comment-subtitle">Are you sure you want to delte this comment?</p>
                            <div className="tw-delete-comment-modal-btn">
                                <button className="btn btn-danger" data-bs-dismiss="modal"
                                    aria-label="Close">Cancel</button>
                                <button className="btn btn-success">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Delete Comment Modal End --> */}
    </>
  )
}
