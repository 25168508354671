

import React from 'react'

const PageNotFound = () => {
  return (
<div className="text-center m-5">
    <h1 className='text-danger'>
        Page Not Found !!!!!!!!!!!
        <br/>
      404  
    </h1>
   </div>
  )
}

export default PageNotFound
