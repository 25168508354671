import React from 'react'
import TransactionHistoryItem from './TransactionHistoryItem'

export default function TransactionHistory() {
  return (
    <>
        <div className="tw-wallet-history-filter">
            <div className="tw-wallet-history-filter-item">
                <label className="tw-wallet-history-filter-label">Filter from</label>
                <div className="tw-wallet-history-filter-input">
                    <input type="text" className="form-control" />
                    <span className="tw-wallet-history-filter-input-icon">
                        <img src="assets/images/calendar-icon.png" alt="calendar-icon"
                            className="img-fluid" />
                    </span>
                </div>
            </div>
            <div className="tw-wallet-history-filter-item">
                <label className="tw-wallet-history-filter-label">Filter to</label>
                <div className="tw-wallet-history-filter-input">
                    <input type="text" className="form-control" />
                    <span className="tw-wallet-history-filter-input-icon">
                        <img src="assets/images/calendar-icon.png" alt="calendar-icon"
                            className="img-fluid" />
                    </span>
                </div>
            </div>
            <div className="tw-wallet-history-filter-item">
                <label className="tw-wallet-history-filter-label">Filter by type</label>
                <div className="tw-wallet-history-filter-input">
                    <select className="form-select" defaultValue="">
                        <option value="" disabled ></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
            </div>
            <div className="tw-wallet-history-filter-item">
                <label className="tw-wallet-history-filter-label">Filter by status</label>
                <div className="tw-wallet-history-filter-input">
                    <select className="form-select">
                        <option value="" disabled selected></option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
            </div>
        </div>
        <div className="tw-wallet-history-table">
            <div className="table-responsive">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">TYPE</th>
                            <th scope="col">DATE</th>
                            <th scope="col">AMOUNT</th>
                        </tr>
                    </thead>
                    <tbody>
                    
                        <TransactionHistoryItem></TransactionHistoryItem>
                        
                    </tbody>
                </table>
            </div>
        </div>
    </>
  )
}
