

import React, { useState } from 'react'
import { toast } from 'react-toastify'

export default function ChangePersonaDetails() {

    const userdata = localStorage.getItem('user_info')
     let data = JSON.parse(userdata)
    // let {user} = data;
    //  console.log(user,"user")
    //   let newUser={
    //      user_id :user.id,
    //      name:user.first_name,
    //      surname:user.last_name,
    //      address:user.address,
    //      phone_number:user.phone_number,
    //      country_name:user.country_name,
      
    //   }
      console.log(data,"gjkkkl")

    const [userData , setUserData] = useState([])
          
    const [user, setUser] = useState({
        user_id:data.user.id,
        name:data.user.first_name,
        surname:data.user.last_name,
        address:data.user.address,
        phone_number:data.user.phone_number,
        country_name:data.user.country_name,
      })
        // updating local storage

 console.log(userData)


      const inputHandler =(e)=>{
                
        setUser({...user,[e.target.name]:e.target.value})

      } 

      const API_URL ='http://nodeapi.notiondemoprojects.com:8080/update_personal_details'
      const  changeUserDetail = async(e)=>{
   
       try {
    

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(user)
        };

        let  result = await fetch(API_URL, requestOptions);
    
         result = await  result.json()

         if(result.status ==='1'){
            toast.success('personal detail changed successfully')
            setUserData(result)
            data.user.id=user.user_id
            data.user.first_name=user.name
            data.user.last_name=user.surname
            data.user.address=user.address
            data.user.phone_number=user.phone_number
            data.user.country_name = user.country_name

            localStorage.setItem('user_info', JSON.stringify(data))
            
         }else{
            toast.error(result.msg?result.msg:"something went wrong")
         }
        
     
       } catch (error) {
        toast.error(error.message)
       
       }

      }                     
  return (
    <>
        {/* <!-- Change Personal Details  Modal Start --> */}
        <div className="modal fade tw-modal-md" id="changePersonalDetailsModal" tabIndex="-1"
            aria-labelledby="changePersonalDetailsModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="changePersonalDetailsModalLabel">CHANGE PERSONAL DETAILS </h5>
                    </div>
                    <div className="modal-body">
                        <div className="fw-personal-details-content">
                            <div className="fw-personal-details-form">
                                <form>
                                    <div className="row">
                                        <div className="col col-12 col-md-3 col-lg-3">
                                            <label className="form-label">Name*</label>
                                        </div>
                                        <div className="col col-12 col-md-9 col-lg-9">
                                            <input name='name'  onChange={inputHandler} value={user.name} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-3 col-lg-3">
                                            <label className="form-label">Surname*</label>
                                        </div>
                                        <div className="col col-12 col-md-9 col-lg-9">
                                            <input name='surname' onChange={inputHandler} value={user.surname}  type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-3 col-lg-3">
                                            <label className="form-label">Address</label>
                                        </div>
                                        <div className="col col-12 col-md-9 col-lg-9">
                                            <input name='address' onChange={inputHandler} value={user.address} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-3 col-lg-3">
                                            <label className="form-label">Country</label>
                                        </div>
                                        <div className="col col-12 col-md-9 col-lg-9">
                                            <input name='country_name' onChange={inputHandler} value={user.country_name} type="text" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-3 col-lg-3">
                                            <label className="form-label">Phone</label>
                                        </div>
                                        <div className="col col-12 col-md-9 col-lg-9">
                                            <input name='phone_number' onChange={inputHandler} value={user.phone_number} type="number" className="form-control" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-md-3 col-lg-3">
                                            <label className="form-label">E-mail*</label>
                                        </div>
                                        <div className="col col-12 col-md-9 col-lg-9">
                                            <input type="email" className="form-control" value={data.user.email} readOnly />
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <p className="fw-personal-details-notes">*mandatory field <br /> Your personal details will not
                                be shown publicly</p>
                            <div className="fw-subscription-plans-btn">
                                <button className="btn btn-danger btn-sm" data-bs-dismiss="modal"
                                    aria-label="Close">CANCEL</button>
                                <button className="btn btn-success btn-sm" data-bs-dismiss="modal" type="submit" onClick={changeUserDetail}
                                    aria-label="Close">SUBMIT</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Change Personal Details  Modal End -->  */}
    </>
  )
}
