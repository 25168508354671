import React, {useState} from 'react';
import {useNavigate} from "react-router-dom"
import { toast } from 'react-toastify';
import LoginModal from '../Login/LoginModal';

export default function RegidterModal() {
    const [subdomain_name, setSubdomain_name]=useState("");
    const [first_name, setFirst_name]=useState("");
    const [email, setEmail]=useState("");
    const [password, setPassword]=useState("");
    const [togleModel , setToggleModel] = useState(false)
    const navigate=useNavigate();


    function registerHandler (e) {
        e.preventDeafult();        
    }


    async function signUp (){
        let userData = {subdomain_name, first_name, email, password}
        

        if (subdomain_name  ==  "") {
            toast.error('Subdomain Name is required.')
        } else if (first_name  ==  "") {
            toast.error('Display Name is required.');
        } else if (email  ==  "") {
          toast.error('Email is required.')
        } else if (password  ==  "") {
            toast.error('Password is required.');
        }  else {

          try {
            // calling api for registed
            let result = await fetch("http://nodeapi.notiondemoprojects.com:8080/register", {
              
                method:'POST',
                headers:{
                    "Content-Type":"application/json",
                    "Accept":"application/json"
                    
                },
                body:JSON.stringify(userData)
            });


            result = await result.json();
            console.log(result)
       
            if  ( result.status === "1") {
                toast.success('user has been registed successfully')
                localStorage.setItem("user_info", JSON.stringify(result));
                setToggleModel(!togleModel)
                setSubdomain_name("");
                setFirst_name("");
                setEmail("");
                setPassword("");
                
            } else {

                toast.error(result.msg?result.msg:"something went wrong");
               
            }
          } catch (error) {
            toast.error(error.message)
          }
          
        }


    }
  return (
    <>
        {/* <!-- Register Modal Start --> */}
        <div className="modal fade" id="registerModal" tabIndex="-1" aria-labelledby="registerModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="registerModalLabel">SIGN UP</h5>

                    </div>
                    <div className="modal-body">
                        <div className="tw-login-register-content">
                            <form onSubmit={registerHandler}>
                                <div className="tw-domain-content">
                                    <div className="tw-subdomain-area">
                                        <label className="form-label">Subdomain*</label>
                                        <input type="text" value={subdomain_name} onChange={(e)=>setSubdomain_name(e.target.value)} className="form-control" />
                                        <label className="form-label">.webname.com</label>
                                    </div>
                                    <div className="tw-subdomain-area">
                                        <label className="form-label">Displayname*</label>
                                        <input type="text" value={first_name} onChange={(e)=>setFirst_name(e.target.value)} className="form-control" />
                                    </div>
                                </div>
                                <ul className="tw-social-login-list">
                                    <li className="tw-social-login-item">
                                        <a href="/" className="tw-social-login-link">
                                            <img src="assets/images/google-icon.png" alt="google-icon" className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="tw-social-login-item">
                                        <a href="/" className="tw-social-login-link">
                                            <img src="assets/images/social-facebook-icon.png" alt="social-facebook-icon"
                                                className="img-fluid" />
                                        </a>
                                    </li>
                                    <li className="tw-social-login-item">
                                        <a href="/" className="tw-social-login-link">
                                            <img src="assets/images/apple-icon.png" alt="apple-icon" className="img-fluid" />
                                        </a>
                                    </li>
                                </ul>
                                <p className="tw-login-register-or-devider">or</p>
                                <div className="tw-login-register-form">
                                    <div className="tw-login-register-input">
                                        <label className="form-label">Email address</label>
                                        <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" />
                                    </div>
                                    <div className="tw-login-register-input">
                                        <label className="form-label">Password</label>
                                        <input type="password" value={password} onChange={(e)=>setPassword(e.target.value)} className="form-control" />
                                    </div>
                                    {/* <div className="tw-login-register-input">
                                        <label className="form-label">Confirm password</label>
                                        <input type="password" className="form-control"  value={confirmPassword} onChange={(e)=>setconfirmPassword(e.target.value)} />
                                    </div> */}
                                </div>
                                <div className="form-check tw-term-condition-check">
                                    <input className="form-check-input" type="checkbox" id="regPrivacyCheck" />
                                    <label className="form-check-label" htmlFor="regPrivacyCheck">
                                        I agree with the Terms&Conditions and Privacy Policy
                                    </label>
                                </div>
                                <div className="tw-login-register-submit-btn">
                                    <button onClick={signUp} type="button"   className="btn btn-link">SIGN UP</button>
                                </div>
                                <p className="tw-login-register-form-notes"><span>Already have an account?</span> <span
                                        data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal"
                                        data-bs-target="#loginModal">Sign
                                        in</span></p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Register Modal End -->  */}
     
    </>
  )
}
