import React from 'react'

export default function ConfirmationPurchaseModal() {
  return (
    <>
        {/* <!-- Confirmation OF Purchase Modal Start --> */}
        <div className="modal fade tw-modal-md" id="confirmPurchaseModal" tabIndex="-1"
            aria-labelledby="confirmPurchaseModalLabel" aria-hidden="true">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="confirmPurchaseModalLabel">CONFIRM PURCHASE</h5>
                    </div>
                    <div className="modal-body tw-report-modal-body">
                        <div className="tw-login-register-content tw-report-form-content">
                            <p className="tw-delete-comment-subtitle text-start">By confirming you will get subscribed to
                                "bloggername"
                                services and 35€ will be substracted from your balance.</p>
                            <div className="tw-delete-comment-modal-btn">
                                <button className="btn btn-danger" data-bs-dismiss="modal"
                                    aria-label="Close">Cancel</button>
                                <button className="btn btn-success" data-bs-dismiss="modal"
                                    aria-label="Close">Confirm</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {/* <!-- Confirmation OF Purchase Modal End -->  */}
    </>
  )
}
