import React from 'react'

export default function SellerEarning() {
  return (
    <>
            <div className="tw-overview-content">
                <div className="tw-overview-content__inner">
                    <div className="row g-0">
                        <div className="col col-12 col-md-4 col-lg-4">
                            <label htmlFor="inputEmail3" className="form-label">Overview</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                            <select className="form-select" defaultValue="6month">
                                <option value="">Last 3 months</option>
                                <option value="6month">Last 6 months</option>
                                <option value="">Last Year</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tw-all-overview-content">
                <h5 className="tw-all-overview-title">All-time</h5>
                <div className="tw-all-overview-list">
                    <div className="tw-all-overview-item">
                        <label className="tw-all-overview-label">Payments received</label>
                        <span className="tw-all-overview-span">0€</span>
                    </div>
                    <div className="tw-all-overview-item">
                        <label className="tw-all-overview-label">Payments due</label>
                        <span className="tw-all-overview-span">0€</span>
                    </div>
                    <div className="tw-all-overview-item">
                        <label className="tw-all-overview-label">On hold</label>
                        <span className="tw-all-overview-span">0€</span>
                    </div>
                    <div className="tw-all-overview-item">
                        <label className="tw-all-overview-label">Total earnings</label>
                        <span className="tw-all-overview-span">0€</span>
                    </div>
                </div>
            </div> 
    </>
  )
}
