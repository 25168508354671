import React, { useEffect, useState } from "react";
import MyCoupon from "./MyCoupon";
import { NavLink } from "react-router-dom";

import { toast } from "react-toastify";
import axios from "axios";

export default function NewPickPage() {
  // making and listing coupun
  const [coupun, setCoupun] = useState([]);
  const [changeText, setChangeText] = useState("Add To Coupon");

  const [inputValue, setInputValue] = useState({
    bookmaker: "Pinnacle,4",
    sport: "American Football,131506",
    market: "Under/Over Including Overtime,28",
    competition: "NFL,75",
    event: "Under/Over Including Overtime,8789002",
    bet: "bet1,28" ,
    checkPaid: "p",
    analysis: "",
  });

 

  // const [fpValue, SetfpValue] = useState({
  //     checkPaid: "",
  //     analysis: ""
  // })

  // const freePaidValue = (e) => {
  //     SetfpValue({ ...fpValue, [e.target.name]: e.target.value })
  // }

  const inputHandler = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const addToCouponHandler = (e) => {
    e.preventDefault();

    if (coupun.length > 9) {
      toast.error("you only add 10 coupuns");
    } else {
      setCoupun([...coupun, inputValue]);
      if (coupun.length >= 0) {
        toast.success(`your created  ${coupun.length + 1} pick`);
      }
    }

    if (coupun.length >= 0) {
      setChangeText("Add more Coupon");
    }
  };

  const userdata = localStorage.getItem("user_info");
  const data = JSON.parse(userdata);
 
 
  const [tipsCat, setTipsCat] = useState([]);
  const [sport, setSport] = useState([]);
  const [comp, setComp] = useState([])
  const [event, setEvent] = useState([])
 const [market, setmarket] = useState([])
 const [bets, setBets] = useState([]);
  // get tipsCat  from server

  const token = 'Bearer e435fad07c732fec513b2f4e35108da300427cc3c9a3acdec3a7ed0e0c979350'


  const getTipsCat =  async() => {
    
    try {
     
     let res =   await axios.get("https://odds.prod.betnclubs.com/bookmakers",{
       headers:{
           "Content-Type": "application/json",
           "Access-Control-Allow-Origin": "*",
           "Authorization": token
       }
   })
    
     setTipsCat(res.data)
   
 
    } catch (error) {
      
    } 

}
 



// sport api 
  const getSport = async () => {
   let result = await fetch(
      `https://odds.prod.betnclubs.com/${inputValue.bookmaker.split(',')[1]}/sports`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": token
        }
        
      }
    )
     result = await result.json()
     setSport(result)
   
  

  };



  // get competition  api
  const getCompetition = async () => {
    let result = await fetch(
      `https://odds.prod.betnclubs.com/${inputValue.bookmaker.split(',')[1]}/${inputValue.sport.split(',')[1]}/competitions`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": token
        }
        
      }
    );
    result = await result.json();
    setComp(result);
    console.log(result,"compo")
  };

  // get Event api

  const getEvent = async () => {
    const url = `https://odds.prod.betnclubs.com/${inputValue.bookmaker.split(',')[1]}/${inputValue.sport.split(',')[1]}/${inputValue.competition.split(',')[1]}/events`
    let result = await fetch( url,
      
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": token
        }
        
      }
    );
    result = await result.json();
    setEvent(result);
   
  };
  
 // get api from market


 const getMarket = async () => {
   const url = `https://odds.prod.betnclubs.com/${inputValue.bookmaker.split(',')[1]}/${inputValue.sport.split(',')[1]}/${inputValue.competition.split(',')[1]}/${inputValue.event.split(',')[1]}/markets`
   let result = await fetch( url,
     
     {
       method: "GET",
       headers: {
         "Content-Type": "application/json",
         "Access-Control-Allow-Origin": "*",
         "Authorization": token
       }
       
     }
   );
   result = await result.json();
   setmarket(result);
   
 };
 


  // get bets from server
 
  const getBets = async () => {
    const url = `https://odds.prod.betnclubs.com/${inputValue.bookmaker.split(',')[1]}/${inputValue.sport.split(',')[1]}/${inputValue.competition.split(',')[1]}/${inputValue.event.split(',')[1]}/${inputValue.bet.split(',')[1]}/bets`
    let result = await fetch( url,
      
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Authorization": token
        }
        
      }
    );
    result = await result.json();
    setBets(result);
  
    
  };

  console.log(bets,"jjjjj")
 

  // create pick and sending data to database

  // .... getting array of ids.....
  let arrayofBookmaker = [];
  arrayofBookmaker = coupun.map((id) => id.bookmaker.split(",")[1]);

  let arrayofSport = [];
  arrayofSport = coupun.map((id) => id.sport.split(",")[1]);

  let arrayoBets = [];
  arrayoBets = coupun.map((id) => id.bet.split(",")[1]);

  let arrayofCompetition = [];
  arrayofCompetition = coupun.map((id) => id.competition);

  let arrayofMarket = [];
  arrayofMarket = coupun.map((id) => id.market);
  let arrayofEvent = [];
  arrayofEvent = coupun.map((id) => id.event);
  let arraofCheckPaid = [];
  arraofCheckPaid = coupun.map((id) => id.checkPaid);
  let arraofAnalysis = [];
  arraofAnalysis = coupun.map((id) => id.analysis);
  //......................

  const createPick = async () => {
    if (coupun.length === 0) {
      toast.info("firstly add the coupon");
    } else {
      try {
        // calling api post
        let result = await fetch(
          "http://nodeapi.notiondemoprojects.com:8080/add_tip",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              user_id: data.user.id,
              bookmaker_id: arrayofBookmaker,
              sport_id: arrayofSport,
              competition: arrayofCompetition,
              event: arrayofEvent,
              market: arrayofMarket,
              bet_id: arrayoBets,
              is_paid: arraofCheckPaid,
              analysis: arraofAnalysis,
            }),
          }
        );
        result = await result.json();

        if (result.status === "1") {
          toast.success("your pick has been successfully created");
        } else {
          toast.error(result.msg ? result.msg : "something went wrong");
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {

    getTipsCat();
    getSport()
    getCompetition()
    getEvent()
    getMarket()
    
    getBets()
   
  }, [inputValue.bookmaker,inputValue.sport,inputValue.competition,inputValue.event, inputValue.market,inputValue.bet]);



  

  return (
    <>
      {/* <!-- Blog Section End --> */}
      <section className="tw-profile-section tw-own-profile-section">
        <div className="container">
          <div className="row">
            {/* <div className="col col-12 col-md-12 col-lg-12 col-xl-2">
              <div className="tw-bookmaker-content">
                            <div className="tw-bookmaker-heading">
                                <h3 className="tw-bookmaker-title">BOOKMAKER</h3>
                            </div>
                            <ul className="tw-bookmaker-list">
                                {tipsCat.map((t)=>{
                                    return(
                                        <li className="tw-bookmaker-item">
                                    <a href="/new-pick" className="tw-bookmaker-link active" key={t.id} >{[t.name,t.id]}</a>
                                </li>
                                    )
                                })}

              </ul>
                        </div>
            </div> */}

            <div className="col col-12 col-md-12 col-lg-12 col-xl-8">
              <div className="tw-new-pick-content">
                <div className="tw-all-tip-header">
                  <h3 className="tw-all-tip-header-title">NEW PICK</h3>
                </div>
                <div className="tw-new-pick-content__inner">
                  <div className="tw-new-pick-form">
                    <form>
                      {/* adding new categories here */}

                      <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">BOOKMAKER</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <select
                            onChange={inputHandler}
                            name="bookmaker"
                            className="form-select"
                          >
                          

                            {tipsCat.map((t) => {
                              return (
                                <option key={t.id} value={[t.name, t.id]}>
                                  {t.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label htmlFor="inputEmail3" className="form-label">
                            Sport
                          </label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <select
                            onChange={inputHandler}
                            name="sport"
                            id="inputState"
                            className="form-select"
                          >
                            {sport.map((s) => {
                              return (
                                <option key={s.id} value={[s.name, s.id]}>
                                  {s.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">Competition</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <div className="tw-new-pick-form-input">
                            <input
                              type="text"
                              className="form-control"
                              onChange={inputHandler}
                              name="competition"
                              defaultValue="UEFA Champions League"
                            />
                            <span className="tw-new-pick-form-icon">
                              <img
                                src="assets/images/search-icon.png"
                                alt="search-icon"
                                className="img-fluid"
                              />
                            </span>
                          </div>
                        </div>
                      </div> */}


                       <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label htmlFor="inputEmail3" className="form-label">
                          Competition
                          </label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <select
                            onChange={inputHandler}
                            name="competition"
                            id="inputState"
                            className="form-select"
                          >
                            {comp.map((c) => {
                              return (
                                <option key={c.id} value={[c.name,c.id]}>   
                                  {c.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>


                      {/* <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">Event</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <div className="tw-new-pick-form-input">
                            <input
                              onChange={inputHandler}
                              name="event"
                              type="text"
                              className="form-control"
                              placeholder="Pick event"
                            />
                            <span className="tw-new-pick-form-icon">
                              <img
                                src="assets/images/search-icon.png"
                                alt="search-icon"
                                className="img-fluid"
                              />
                            </span>
                          </div>
                        </div>
                      </div> */}

                         
                         <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label htmlFor="inputEmail3" className="form-label">
                          Event
                          </label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <select
                            onChange={inputHandler}
                            name="event"
                            id="inputState"
                            className="form-select"
                          >
                            {event.map((e) => {
                        
                              return (

                               e.PART.map((de,index)=>{
                               
                               
                                return (
                                  
                                  <option key={de.ID} value={[de.N,e.FID]}>   
                                  {de.N}
                                </option>
                                )
                               })
                              );
                            })}
                          </select>
                        </div>
                      </div>



                      {/* <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">Market</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <div className="tw-new-pick-form-input">
                            <input
                              onChange={inputHandler}
                              name="market"
                              type="text"
                              className="form-control"
                              defaultValue="Total corners"
                            />
                            <span className="tw-new-pick-form-icon">
                              <img
                                src="assets/images/search-icon.png"
                                alt="search-icon"
                                className="img-fluid"
                              />
                            </span>
                          </div>
                        </div>
                      </div> */}

                       <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label htmlFor="inputEmail3" className="form-label">
                           Market
                          </label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <select
                            onChange={inputHandler}
                            name="market"
                            id="inputState"
                            className="form-select"
                          >
                            {market.map((m) => {
                              return (
                                <option key={m.ID} value={[m.N,m.ID]}>   
                                  {m.N}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">Bet</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <select
                            onChange={inputHandler}
                            name="bet"
                            defaultValue={""}
                            id="inputState"
                            className="form-select"
                          >
                           { bets && bets.map((b) =>
                           <option key={b.ID} value={[b.N, b.ID]}>
                           {b.N}
                           </option>
                      
                          
                            )}
                            
                          </select>
                        </div>
                      </div>

                      {/* adding free or paid section */}
                      <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">choose </label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          <div className="tw-new-pick-form-input">
                            <input
                              onChange={inputHandler}
                              name="checkPaid"
                              className="form-check-input mx-2"
                              type="radio"
                              id="inlineRadio1"
                              value="p"
                              defaultChecked
                            />

                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Paid pick
                            </label>
                            <input
                              onChange={inputHandler}
                              name="checkPaid"
                              value="f"
                              className="form-check-input mx-2  "
                              type="radio"
                              id="inlineRadio2"
                            />

                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio2"
                            >
                              Free pick
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* adding analysis section   */}
                      <div className="row">
                        <div className="col col-12 col-md-4 col-lg-4">
                          <label className="form-label">Analysis</label>
                        </div>
                        <div className="col col-12 col-md-8 col-lg-8">
                          {/* <div className="tw-my-pick-analysis"> */}
                          {/* <label className="form-label">Analysis:</label> */}
                          <textarea
                            onChange={inputHandler}
                            name="analysis"
                            id=""
                            cols="30"
                            rows="5"
                            className="form-control"
                            placeholder="Adding a few lines of analysis to your tip, makes it more appealing to your customers, increase your blog popularity and win more followers."
                          ></textarea>
                          {/* </div> */}
                        </div>
                      </div>

                      {/* adding new add button  */}

                      <div className="row">
                        <div className="col col-12 col-md-8 col-lg-8 ms-auto ">
                          <button
                            onClick={addToCouponHandler}
                            type="submit"
                            className="btn btn-danger"
                          >
                            {changeText}
                          </button>
                        </div>
                      </div>

                      {/* <div className="row">
                                            <div className="col col-12 col-md-8 col-lg-8 ms-auto ">
                                                <button type="submit" className="btn btn-danger">ADD TO COUPON</button>
                                            </div>
                                        </div> */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 col-md-12 col-lg-12 col-xl-2">
              <div className="tw-my-coupon-content">
                <div className="tw-my-coupon-heading">
                  <h3 className="tw-my-coupon-title">MY COUPON</h3>
                </div>
                <div className="tw-my-coupon-content__inner">
                  <div className="tw-my-coupon-card-content">
                    <MyCoupon coupun={coupun} setCoupun={setCoupun}></MyCoupon>
                  </div>
                  <div className="tw-my-coupon-odds">
                    <label className="tw-my-coupon-odds-lable">ODDS: </label>
                    <span className="tw-my-coupon-odds-text">8.70</span>
                  </div>
                  <div className="tw-my-coupon-stake">
                    <label className="tw-my-coupon-stake-lable">
                      Stake (1-10):
                    </label>
                    <div className="tw-my-coupon-stake-select">
                      <select
                        defaultValue={""}
                        id="inputState"
                        className="form-select"
                      >
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                      </select>
                    </div>
                  </div>
                  {/* ................ */}
                  {/* <div className="tw-my-pick-type">
                                        <div className="form-check form-check-inline">
                                            <input onChange={freePaidValue} name="checkPaid" className="form-check-input" type="radio"
                                                id="inlineRadio1" value="p" defaultChecked />
                                           
                                            <label className="form-check-label" htmlFor="inlineRadio1">Paid pick</label>
                                        </div>
                                        <div className="form-check form-check-inline">
                                            <input onChange={freePaidValue} name="checkPaid" value="f" className="form-check-input" type="radio"
                                                id="inlineRadio2" />
                                          
                                            <label className="form-check-label" htmlFor="inlineRadio2">Free pick</label>
                                        </div>
                                    </div> */}
                  {/* <div className="tw-create-my-pick-btn">
                                    <NavLink className="btn btn-danger" to="/new-pick-created"> CREATE PICK </NavLink>
                                    <button  onClick={createPick} type="submit" className="btn btn-danger">CREATE PICK</button>
                                </div> */}
                  {/* <div className="tw-my-pick-analysis">
                                        <label className="form-label">Analysis:</label>
                                        <textarea onChange={freePaidValue} name="analysis" id="" cols="30" rows="5" className="form-control"
                                            placeholder="Adding a few lines of analysis to your tip, makes it more appealing to your customers, increase your blog popularity and win more followers."></textarea>
                                    </div> */}

                  {/* name="PickTypeRadioOptions" */}
                  {/* name="PickTypeRadioOptions" */}
                  {/* defaultValue="option2" */}
                  {/* ............. */}
                  <div className="tw-create-my-pick-btn mb-0">
                    {/* <NavLink className="btn btn-danger" to="/new-pick-created"> CREATE PICK </NavLink> */}
                    <button
                      onClick={createPick}
                      type="submit"
                      className="btn btn-danger"
                    >
                      CREATE PICK
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Blog Section End -->  */}
    </>
  );
}
