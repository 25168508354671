import React from 'react'

export default function MyCouponSaved() {
  return (
    <>  
        <div className="tw-my-coupon-seved">
            <a href="/" className="tw-my-coupon-seved-link">
                <span className="tw-my-coupon-seved-text">Bet saved!</span>
                <span className="tw-my-coupon-seved-icon">
                    <svg width="8" height="16" viewBox="0 0 8 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M5.58837 8.00208L0.293686 14.0513C-0.0978956 14.499 -0.0978956 15.223 0.293686 15.666C0.685267 16.1137 1.31846 16.109 1.71004 15.666L7.70874 8.81182C8.08782 8.37837 8.09615 7.68295 7.7379 7.23522L1.71421 0.33342C1.51842 0.109552 1.26014 0 1.00603 0C0.75192 0 0.493643 0.109552 0.297852 0.33342C-0.09373 0.781156 -0.09373 1.50515 0.297852 1.94813L5.58837 8.00208Z" fill="white"></path>
                    </svg>
                </span>
            </a>
        </div>
    </>
  )
}