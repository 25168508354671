import React, { useEffect, useState } from 'react'
import {toast} from 'react-toastify'
export default function Withdraw() {

    const oldData = localStorage.getItem('user_info')
    const data = JSON.parse(oldData)
    const [wallet, setWallet] = useState('')


    const [input, setInput] = useState({
        amount:"",
        payment_method:"Card"
    })
 
    const getWallet = async()=>{

        try {
            
            let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/get_wallet_balance',{
                method:"POST",
                headers:{
                    "Content-Type":"application/json",
                     "Accept":"application/json"
                },
                body:JSON.stringify({user_id:data.user.id})
            })
             result = await result.json()
             if(result.status === '1'){
                setWallet(result.user)
    
             }else{
                toast.error(result.msg?result.msg:"something went wrong");
             }
    
        } catch (error) {
            toast.error(error.message)
        }
       }
    
       useEffect(()=>{
        getWallet()
       },[])

    
    const inputHandler = (e)=>{
        setInput({...input,[e.target.name]:e.target.value})
    }


   
    
    const postwithdrawal = async(e)=>{
        e.preventDefault()
     
        if(input.amount > wallet.wallet_amount ){
            toast.error(`your balance is less than  ${input.amount}`)
        }else{

            try {
       
                let result = await fetch('http://nodeapi.notiondemoprojects.com:8080/withdrawal_to_wallet',{
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json",
                         "Accept":"application/json"
                    },
                    body:JSON.stringify({user_id:data.user.id,amount:input.amount,payment_method:input.payment_method})
                })
                 result = await result.json()
                 
                 if(result.status ==='1'){
                 toast.success('withdraw has been successfully')
        
                 }else{
                    toast.error(result.msg?result.msg:"something went wrong");
                 }
        
            } catch (error) {
                toast.error(error.message)
            }
        }
    
    }

  return (
    <>
        <div className="tw-deposit-form">
            <form>
                <div className="row">
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Withdraw amount (EUR):</label>
                    </div>
                    <div className="col col-12 col-md-8 col-lg-8">
                        <input name="amount" onChange={inputHandler} type="number" className="form-control" />
                    </div>
                </div>
                <div className="row tw-deposit-payment-method">
                    <div className="col col-12 col-md-4 col-lg-4">
                        <label className="form-label">Payment method: </label>
                    </div>
                    <div className="col col-12 col-md-8 col-lg-8">
                        <div className="form-check">
                            <input onChange={inputHandler} className="form-check-input" type="radio"
                                // name="WithdrawPaymentMethodRadio"
                                name='payment_method'
                                value="Card"
                                id="WithdrawPaymentMethodRadio1" defaultChecked />
                            <label className="form-check-label"
                                htmlFor="WithdrawPaymentMethodRadio1">
                                Card
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={inputHandler} className="form-check-input" type="radio"
                                // name="WithdrawPaymentMethodRadio"
                                name='payment_method'
                                value="Paypal"
                                id="WithdrawPaymentMethodRadio2" />
                            <label className="form-check-label"
                                htmlFor="WithdrawPaymentMethodRadio2">
                                PayPal
                            </label>
                        </div>
                        <div className="form-check">
                            <input onChange={inputHandler}  className="form-check-input" type="radio"
                                // name="WithdrawPaymentMethodRadio"
                                name='payment_method'
                                value="Skrill"
                                id="WithdrawPaymentMethodRadio3" />
                            <label className="form-check-label"
                                htmlFor="WithdrawPaymentMethodRadio3">
                                Skrill
                            </label>
                        </div>
                    </div>
                </div>
                <div className="tw-agree-rules-check">
                    <div className="row">
                        <div className="col col-12 col-md-8 col-lg-8 ms-auto">
                            <div className="form-check">
                                <input className="form-check-input" type="checkbox"
                                    id="gridCheck" />
                                <label className="form-check-label" htmlFor="gridCheck">
                                    I aknowledge that I read and agree with the <a
                                        href="/">Wallet
                                        Rules</a>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-12 col-md-8 col-lg-8 ms-auto">
                        <div className="tw-wallet-deposit-btn">
                            <button onClick={postwithdrawal} className="btn btn-primary">Withdraw</button>
                        </div>
                    </div>
                </div>
            </form>
        </div> 
    </>
  )
}
