import React from 'react'

export default function BuyerSubscriptionsCards() {
  return (
    <>
        <div className="tw-tipsters-card">
                <div className="tw-tipsters-profile">
                    <img src="assets/images/tips-profile-img3.png" alt="tips-profile-img1"
                        className="img-fluid" />
                </div>
                <div className="tw-tipsters-details">
                    <h3 className="tw-tipsters-title">WinnerOU</h3>
                    <a className="tw-tipsters-subdomain">WinnerOU.webname.com</a>
                    <p className="tw-tipsters-country">
                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                            className="img-fluid" />
                    </p>
                </div>
                <div className="tw-tipsters-info">
                    <div className="tw-tipsters-info-item">
                        <h4 className="tw-tipsters-info-count">55€/MONTH</h4>
                        <label className="tw-tipsters-info-label">Price</label>
                    </div>
                    <div className="tw-tipsters-info-item">
                        <h4 className="tw-tipsters-info-count">15.01.2022</h4>
                        <label className="tw-tipsters-info-label">Start date</label>
                    </div>
                    <div className="tw-tipsters-info-item">
                        <h4 className="tw-tipsters-info-count">13.02.2022</h4>
                        <label className="tw-tipsters-info-label">End date</label>
                    </div>
                </div>
                <div className="tw-tipsters-action">
                    <button className="btn btn-sm btn-danger" data-bs-toggle="modal"
                        data-bs-target="#cancelSubscriptionModal">
                        <span>CANCEL SUBSCRIPTION </span>
                    </button>
                </div>
            </div>
            <div className="tw-tipsters-card">
                <div className="tw-tipsters-profile">
                    <img src="assets/images/tips-profile-img1.png" alt="tips-profile-img1"
                        className="img-fluid" />
                </div>
                <div className="tw-tipsters-details">
                    <h3 className="tw-tipsters-title">Mr.Stanley</h3>
                    <a className="tw-tipsters-subdomain">MrStanley.webname.com</a>
                    <p className="tw-tipsters-country">
                        <img src="assets/images/country-code-14.png" alt="country-code-14"
                            className="img-fluid" />
                    </p>
                </div>
                <div className="tw-tipsters-info">
                    <div className="tw-tipsters-info-item">
                        <h4 className="tw-tipsters-info-count">75€/MONTH</h4>
                        <label className="tw-tipsters-info-label">Price</label>
                    </div>
                    <div className="tw-tipsters-info-item">
                        <h4 className="tw-tipsters-info-count">12.01.2022</h4>
                        <label className="tw-tipsters-info-label">Start date</label>
                    </div>
                    <div className="tw-tipsters-info-item">
                        <h4 className="tw-tipsters-info-count">11.02.2022</h4>
                        <label className="tw-tipsters-info-label">End date</label>
                    </div>
                </div>
                <div className="tw-tipsters-action">
                    <button className="btn btn-sm btn-danger" data-bs-toggle="modal"
                        data-bs-target="#cancelSubscriptionModal">
                        <span>CANCEL SUBSCRIPTION </span>
                    </button>
                </div>
            </div> 
    </>
  )
}
