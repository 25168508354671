import React from 'react'
import MyCouponSaved from './MyCouponSaved'

export default function newPickSavedPage() {
  return (
    <>
        {/* <!-- Blog Section End --> */}
        <section className="tw-profile-section tw-own-profile-section">
            <div className="container">
                <div className="row">
                    <div className="col col-12 col-md-12 col-lg-12 col-xl-2">
                        <div className="tw-bookmaker-content">
                            <div className="tw-bookmaker-heading">
                                <h3 className="tw-bookmaker-title">BOOKMAKER</h3>
                            </div>
                            <ul className="tw-bookmaker-list">
                                <li className="tw-bookmaker-item">
                                    <a href="/" className="tw-bookmaker-link active">Bet365</a>
                                </li>
                                <li className="tw-bookmaker-item">
                                    <a href="/" className="tw-bookmaker-link">Pinnacle</a>
                                </li>
                                <li className="tw-bookmaker-item">
                                    <a href="/" className="tw-bookmaker-link">WilliamHill</a>
                                </li>
                                <li className="tw-bookmaker-item">
                                    <a href="/" className="tw-bookmaker-link">1XBet</a>
                                </li>
                                <li className="tw-bookmaker-item">
                                    <a href="/" className="tw-bookmaker-link">BetClick</a>
                                </li>
                                <li className="tw-bookmaker-item">
                                    <a href="/" className="tw-bookmaker-link">SBObet</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col col-12 col-md-12 col-lg-12 col-xl-8">
                        <div className="tw-new-pick-content">
                            <div className="tw-all-tip-header">
                                <h3 className="tw-all-tip-header-title">NEW PICK</h3>
                            </div>
                            <div className="tw-new-pick-content__inner">
                                <div className="tw-new-pick-form">
                                    <form>
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label htmlFor="inputEmail3" className="form-label">Sport</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <select defaultValue={''} id="inputState" className="form-select">
                                                    <option value="">Soccer</option>
                                                    <option value="option">Soccer1</option>
                                                    <option value="option">Soccer2</option>
                                                    <option value="option">Soccer3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label className="form-label">Competition</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <div className="tw-new-pick-form-input">
                                                    <input type="text" className="form-control"
                                                        defaultValue="UEFA Champions League" />
                                                    <span className="tw-new-pick-form-icon">
                                                        <img src="assets/images/search-icon.png" alt="search-icon"
                                                            className="img-fluid" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label className="form-label">Event</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <div className="tw-new-pick-form-input">
                                                    <input type="text" className="form-control" placeholder="Pick event" />
                                                    <span className="tw-new-pick-form-icon">
                                                        <img src="assets/images/search-icon.png" alt="search-icon"
                                                            className="img-fluid" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label className="form-label">Market</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <div className="tw-new-pick-form-input">
                                                    <input type="text" className="form-control" defaultValue="Total corners FT" />
                                                    <span className="tw-new-pick-form-icon">
                                                        <img src="assets/images/search-icon.png" alt="search-icon"
                                                            className="img-fluid" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-12 col-md-4 col-lg-4">
                                                <label className="form-label">Bet</label>
                                            </div>
                                            <div className="col col-12 col-md-8 col-lg-8">
                                                <select defaultValue={''} id="inputState" className="form-select">
                                                    <option value="">Pick bet</option>
                                                    <option value="option">Pick bet1</option>
                                                    <option value="option">Pick bet2</option>
                                                    <option value="option">Pick bet3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col col-12 col-md-8 col-lg-8 ms-auto ">
                                                <button type="submit" className="btn btn-danger">ADD TO COUPON</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col col-12 col-md-12 col-lg-12 col-xl-2">
                        <div className="tw-my-coupon-content">
                            <div className="tw-my-coupon-heading">
                                <h3 className="tw-my-coupon-title">MY COUPON</h3>
                            </div>
                            <div className="tw-my-coupon-content__inner">
                                <div className="tw-my-coupon-card-content">

                                    <MyCouponSaved></MyCouponSaved>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <!-- Blog Section End -->  */}
    </>
  )
}