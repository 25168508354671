import React, { useState } from 'react'
import $ from "jquery";
import { toast } from 'react-toastify';



export default function ChangePasswordModal() {


    const email =  localStorage.getItem('user_email') 
     const  verifyEmail = JSON.parse(email)

     const [inputValue , setInputValue] = useState({
        new_password:"",
        conform_password:""
     })
     const [resonse , setResponse] = useState('')

   

     const inputHabdler =(e)=>{
        setInputValue({... inputValue, [e.target.name]:e.target.value})
     }

     const newPasswordHandler = async()=>{
         
     
        if (inputValue.new_password !== inputValue.conform_password){
            toast.error('password is mot match')
        }else if(inputValue.new_password ==='' ){
            toast.error('please filled both input correctly')
        }else{
            try {
            
                const result = await fetch('http://nodeapi.notiondemoprojects.com:8080/new_password',{
                    method:"POST",
                    headers:{
                        "Content-Type":"application/json",
                        "Accept":"application/json"
                    },
                    body:JSON.stringify({email:verifyEmail,new_password:inputValue.new_password})
                })

                const data = await result.json()
                if(data.status === '1' ){
                    toast.success("your password hass been changed successfully")

                    setResponse(data)

                   
                    
                }else{
                    toast.error(data.msg?data.msg:"something went wrong ")
                }
              
               
                
            } catch (error) {
                toast.error(error.message)
            }
    

        }
        
       
     }
  return (
    <>
        {/* <!-- Change Password Modal Start --> */}
        <div className="modal fade" id="changePasswordModal" tabIndex="-1" aria-labelledby="changePasswordModalLabel"
            aria-hidden="true" data-bs-backdrop="static">
            <div className="modal-dialog  modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="changePasswordModalLabel">FORGOT PASSWORD</h5>
                    </div>
                    <div className="modal-body">
                        <div className="tw-login-register-content">
                            <div className="tw-login-register-form">
                                <div className="tw-login-register-input">
                                    <label className="form-label">Enter new password:</label>
                                    <input onChange={inputHabdler} type="password" name="new_password" className="form-control"  />
                                </div>
                                <div className="tw-login-register-input">
                                    <label className="form-label">Confirm new password:</label>
                                    <input onChange={inputHabdler} type="password" name="conform_password" className="form-control"  />
                                </div>
                            </div>
                            <div className="tw-login-register-submit-btn">
                                <button data-bs-dismiss="modal" aria-label="Close" data-bs-toggle="modal" data-bs-target="#LoginModal" type="submit" onClick={newPasswordHandler} className="btn btn-primary">CONFIRM</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
    </>
  )
}
