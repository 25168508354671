import React from 'react'

export default function BuyerTransactionsItems() {
  return (
    <>
        <tr>
            <td>15.01.2022</td>
            <td>55€</td>
            <td>Subscription</td>
        </tr>
        <tr>
            <td>25.01.2022</td>
            <td>5€</td>
            <td>Single bet</td>
        </tr> 
    </>
  )
}
