import React from 'react'

export default function BlogAllTips() {
  return (
    <>
        <div className="tw-all-tip-card">
            <div className="tw-all-tip-body">
                <div className="tw-all-tip-profile">
                    <div className="tw-all-tip-profile-img">
                        <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img2"
                            className="img-fluid" />
                    </div>
                    <p className="tw-all-tip-user-country">
                        <span className="tw-all-tip-user-country-flag">
                            <img src="assets/images/country-code-14.png" alt="country-code-14"
                                className="img-fluid" />
                        </span>
                        <span className="tw-all-tip-user-country-code">+14% (253)</span>
                    </p>
                </div>
                <div className="tw-all-tip-body__inner">
                    <div className="tw-all-tip-body-head">
                        <p className="tw-all-tip-owner"><span> Mr.Stanley </span> published a new
                            pick:</p>
                        <label>
                            <span data-bs-toggle="modal" data-bs-target="#reportFormModal"
                                className="tw-report-icon">
                                <img src="assets/images/info-icon.png" alt="info-icon" className="img-fluid" />
                            </span>
                            <span>11 Feb 2022 11:20</span>
                        </label>
                    </div>
                    <div className="tw-tip-detail-content">
                        <div className="tw-tip-detail__inner">
                            <h2 className="tw-tip-title">Sassuolo v Roma</h2>
                            <div className="tw-tip-match-goal-detail">
                                <p className="tw-tip-match-goal-count">Over 1.5 (Match Goals) @
                                    1.667</p>
                                <span className="tw-tip-match-goals">10/10</span>
                                <span className="tw-tip-match-goal-bet">bet365</span>
                            </div>
                            <p className="tw-football-description">
                                <span className="tw-football-icon">
                                    <img src="assets/images/football-icon.png"
                                        alt="football-icon" className="img-fluid" />
                                </span>
                                <span>Football / Livebet / Kick off: 13 Feb 2022, 19:00</span>
                            </p>
                        </div>
                    </div>
                    <p className="tw-tip-text">At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui
                        blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
                        et quas
                        molestias excepturi sint occaecati cupiditate non provident, similique
                        sunt in
                        culpa qui officia deserunt mollitia animi, id est laborum et dolorum
                        fuga. Et harum
                        quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
                        soluta
                        nobis est eligendi optio cumque nihil impedit quo minus
                        id quod maxime placeat facere possimus, omnis voluptas assumenda est,
                        omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis debitis aut </p>
                    <div className="tw-comment-content">
                        <div className="tw-comment-heading">
                            <div className="tw-comment-heading__inner">
                                <button className="btn btn-sm btn-outline-secondary">
                                    <span className="tw-btn-icon">
                                        <img src="assets/images/like-icon.png" alt="like-icon"
                                            className="img-fluid" />
                                    </span>
                                    <span>Like</span>
                                </button>
                                <button className="btn btn-sm btn-outline-secondary">
                                    <span>Translate</span>
                                </button>
                            </div>
                            <div className="tw-comment-heading__inner">
                                <p className="tw-comment-totla-likes">Likes (15)</p>
                                <p className="tw-comment-totla-likes">Comments (0)</p>
                            </div>
                        </div>
                        <div className="tw-comments-list">
                            <div className="tw-comments-item">
                                <div className="tw-commenter-user-img">
                                    <img src="assets/images/tips-profile-img1.png"
                                        alt="tips-profile-img1" className="img-fluid" />
                                </div>
                                <div className="tw-commenter-user-details">
                                    <div className="tw-commenter-details__heading">
                                        <h3 className="tw-commenter-user-name">Mr.Stanley </h3>
                                        <p className="tw-comment-time">11 Feb 2022 11:25</p>
                                    </div>
                                    <p className="tw-commenter-content">Sed ut perspiciatis unde
                                        omnis iste
                                        natus error sit voluptatem accusantium doloremque
                                        laudantium, totam
                                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                        et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam
                                        voluptatem quia voluptas sit aspernatur aut odit aut
                                        fugit, sed quia
                                        consequuntur magni dolores eos qui ratione voluptatem
                                        sequi
                                        nesciunt. </p>
                                </div>
                            </div>
                            <div className="tw-comments-item">
                                <div className="tw-commenter-user-img">
                                    <img src="assets/images/tips-profile-img2.png"
                                        alt="tips-profile-img2" className="img-fluid" />
                                </div>
                                <div className="tw-commenter-user-details">
                                    <div className="tw-commenter-details__heading">
                                        <h3 className="tw-commenter-user-name">OnlyElite </h3>
                                        <p className="tw-comment-time">11 Feb 2022 11:37</p>
                                        <div className="tw-comment-action">
                                            <a href="/"
                                                className="tw-comment-action-link">Delete</a>
                                            <a href="/" className="tw-comment-action-link">Edit</a>
                                        </div>
                                    </div>
                                    <p className="tw-commenter-content">Sed ut perspiciatis unde
                                        omnis iste
                                        natus error sit voluptatem accusantium doloremque
                                        laudantium, totam
                                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                        et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam
                                        voluptatem quia voluptas sit aspernatur aut odit aut
                                        fugit, sed quia
                                        consequuntur magni dolores eos qui ratione voluptatem
                                        sequi
                                        nesciunt. </p>
                                </div>
                            </div>
                            <div className="tw-comments-item">
                                <div className="tw-commenter-user-img">
                                    <img src="assets/images/tips-profile-img1.png"
                                        alt="tips-profile-img1" className="img-fluid" />
                                </div>
                                <div className="tw-commenter-user-details">
                                    <div className="tw-commenter-details__heading">
                                        <h3 className="tw-commenter-user-name">Mr.Stanley </h3>
                                        <p className="tw-comment-time">11 Feb 2022 11:25</p>
                                    </div>
                                    <p className="tw-commenter-content">Sed ut perspiciatis unde
                                        omnis iste
                                        natus error sit voluptatem accusantium doloremque
                                        laudantium, totam
                                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                        et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam
                                        voluptatem quia voluptas sit aspernatur aut odit aut
                                        fugit, sed quia
                                        consequuntur magni dolores eos qui ratione voluptatem
                                        sequi
                                        nesciunt. </p>
                                </div>
                            </div>
                        </div>
                        <div className="tw-comment-body">
                            <div className="tw-comment-area">
                                <textarea className="form-control" rows="2"></textarea>
                                <button className="btn btn-sm btn-danger">Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                <div className="tw-all-tip-card">
                <div className="tw-all-tip-body">
                <div className="tw-all-tip-profile">
                    <div className="tw-all-tip-profile-img">
                        <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img2"
                            className="img-fluid" />
                    </div>
                    <p className="tw-all-tip-user-country">
                        <span className="tw-all-tip-user-country-flag">
                            <img src="assets/images/country-code-14.png" alt="country-code-14"
                                className="img-fluid" />
                        </span>
                        <span className="tw-all-tip-user-country-code">+14% (253)</span>
                    </p>
                </div>
                <div className="tw-all-tip-body__inner">
                    <div className="tw-all-tip-body-head">
                        <p className="tw-all-tip-owner"><span> OnlyElite </span> published a new
                            pick:</p>
                        <label>
                            <span data-bs-toggle="modal" data-bs-target="#reportFormModal"
                                className="tw-report-icon">
                                <img src="assets/images/info-icon.png" alt="info-icon" className="img-fluid" />
                            </span>
                            <span>11 Feb 2022 11:20</span>
                        </label>
                    </div>
                    <div className="tw-tip-detail-content">
                        <div className="tw-tip-detail__inner">
                            <h2 className="tw-tip-title">Sassuolo v Roma</h2>
                            <div className="tw-tip-match-goal-detail">
                                <p className="tw-tip-match-goal-count">Over 1.5 (Match Goals) @
                                    1.667</p>
                                <span className="tw-tip-match-goals">10/10</span>
                                <span className="tw-tip-match-goal-bet">bet365</span>
                            </div>
                            <p className="tw-football-description">
                                <span className="tw-football-icon">
                                    <img src="assets/images/football-icon.png"
                                        alt="football-icon" className="img-fluid" />
                                </span>
                                <span>Football / Livebet / Kick off: 13 Feb 2022, 19:00</span>
                            </p>
                        </div>
                    </div>
                    <div className="tw-comment-content">
                        <div className="tw-comment-heading">
                            <div className="tw-comment-heading__inner">
                                <button className="btn btn-sm btn-outline-secondary">
                                    <span className="tw-btn-icon">
                                        <img src="assets/images/like-icon.png" alt="like-icon"
                                            className="img-fluid" />
                                    </span>
                                    <span>Like</span>
                                </button>
                            </div>
                            <div className="tw-comment-heading__inner">
                                <p className="tw-comment-totla-likes">Likes (15)</p>
                                <p className="tw-comment-totla-likes">Comments (0)</p>
                            </div>
                        </div>
                        <div className="tw-comment-body">
                            <div className="tw-comment-area">
                                <textarea className="form-control" rows="2"></textarea>
                                <button className="btn btn-sm btn-danger">Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                <div className="tw-all-tip-card">
                <div className="tw-all-tip-body">
                <div className="tw-all-tip-profile">
                    <div className="tw-all-tip-profile-img">
                        <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img2"
                            className="img-fluid" />
                    </div>
                    <p className="tw-all-tip-user-country">
                        <span className="tw-all-tip-user-country-flag">
                            <img src="assets/images/country-code-14.png" alt="country-code-14"
                                className="img-fluid" />
                        </span>
                        <span className="tw-all-tip-user-country-code">+14% (253)</span>
                    </p>
                </div>
                <div className="tw-all-tip-body__inner">
                    <div className="tw-all-tip-body-head">
                        <p className="tw-all-tip-owner"><span> OnlyElite </span> published a new
                            pick:</p>
                        <label>
                            <span data-bs-toggle="modal" data-bs-target="#reportFormModal"
                                className="tw-report-icon">
                                <img src="assets/images/info-icon.png" alt="info-icon" className="img-fluid" />
                            </span>
                            <span>11 Feb 2022 11:20</span>
                        </label>
                    </div>
                    <div className="tw-tip-detail-content">
                        <div className="tw-tip-detail__inner">
                            <h2 className="tw-tip-title">
                                <span className="tw-tip-title-icon">
                                    <img src="assets/images/locked-icon.png" alt="locked-icon"
                                        className="img-fluid" />
                                </span>
                                <span>Paid pick</span>
                            </h2>
                            <div className="tw-tip-match-goal-detail">
                                <p className="tw-tip-match-goal-count">Over 1.5 (Match Goals) @
                                    1.667</p>
                                <span className="tw-tip-match-goals">10/10</span>
                                <span className="tw-tip-match-goal-bet">bet365</span>
                            </div>
                            <p className="tw-football-description">
                                <span className="tw-football-icon">
                                    <img src="assets/images/football-icon.png"
                                        alt="football-icon" className="img-fluid" />
                                </span>
                                <span>Football / Livebet / Kick off: 13 Feb 2022, 19:00</span>
                            </p>
                        </div>
                    </div>
                    <div className="tw-buy-bid-content">
                        <button className="btn btn-danger btn-sm">
                            <span>
                                <img src="assets/images/cart-icon.png" alt="cart-icon"
                                    className="img-fluid" />
                            </span>
                            <span>Buy for 5.00 €</span>
                        </button>
                    </div>
                </div>
                </div>
                </div>
                <div className="tw-all-tip-header">
                <h3 className="tw-all-tip-header-title">GRADED PICKS</h3>
                <span className="tw-all-tip-icon">
                <img src="assets/images/reload-icon.png" alt="reload-icon" className="img-fluid" />
                </span>
                </div>
                <div className="tw-all-tip-card">
                <div className="tw-all-tip-body">
                <div className="tw-all-tip-profile">
                    <div className="tw-all-tip-profile-img">
                        <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img2"
                            className="img-fluid" />
                    </div>
                    <p className="tw-all-tip-user-country">
                        <span className="tw-all-tip-user-country-flag">
                            <img src="assets/images/country-code-14.png" alt="country-code-14"
                                className="img-fluid" />
                        </span>
                        <span className="tw-all-tip-user-country-code">+14% (253)</span>
                    </p>
                </div>
                <div className="tw-all-tip-body__inner">
                    <div className="tw-all-tip-body-head">
                        <p className="tw-all-tip-owner"><span> OnlyElite </span> published a new
                            pick:</p>
                        <label>
                            <span data-bs-toggle="modal" data-bs-target="#reportFormModal"
                                className="tw-report-icon">
                                <img src="assets/images/info-icon.png" alt="info-icon" className="img-fluid" />
                            </span>
                            <span>11 Feb 2022 11:20</span>
                        </label>
                    </div>
                    <div className="tw-tip-detail-content">
                        <div className="tw-tip-detail__inner">
                            <h2 className="tw-tip-title">Sassuolo v Roma</h2>
                            <div className="tw-tip-match-goal-detail">
                                <p className="tw-tip-match-goal-count">Over 1.5 (Match Goals) @
                                    1.667</p>
                                <span className="tw-tip-match-goals">10/10</span>
                                <span className="tw-tip-match-goal-bet">bet365</span>
                            </div>
                            <p className="tw-football-description">
                                <span className="tw-football-icon">
                                    <img src="assets/images/football-icon.png"
                                        alt="football-icon" className="img-fluid" />
                                </span>
                                <span>Football / Livebet / Kick off: 13 Feb 2022, 19:00</span>
                            </p>
                        </div>
                    </div>
                    <div className="tw-comment-content">
                        <div className="tw-comment-heading">
                            <div className="tw-comment-heading__inner">
                                <button className="btn btn-sm btn-outline-secondary">
                                    <span className="tw-btn-icon">
                                        <img src="assets/images/like-icon.png" alt="like-icon"
                                            className="img-fluid" />
                                    </span>
                                    <span>Like</span>
                                </button>
                            </div>
                            <div className="tw-comment-heading__inner">
                                <p className="tw-comment-totla-likes">Likes (15)</p>
                                <p className="tw-comment-totla-likes">Comments (0)</p>
                            </div>
                        </div>
                        <div className="tw-comment-body">
                            <div className="tw-comment-area">
                                <textarea className="form-control" rows="2"></textarea>
                                <button className="btn btn-sm btn-danger">Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                </div>
                <div className="tw-all-tip-card">
                <div className="tw-all-tip-body">
                <div className="tw-all-tip-profile">
                    <div className="tw-all-tip-profile-img">
                        <img src="assets/images/tips-profile-img2.png" alt="tips-profile-img2"
                            className="img-fluid" />
                    </div>
                    <p className="tw-all-tip-user-country">
                        <span className="tw-all-tip-user-country-flag">
                            <img src="assets/images/country-code-14.png" alt="country-code-14"
                                className="img-fluid" />
                        </span>
                        <span className="tw-all-tip-user-country-code">+14% (253)</span>
                    </p>
                </div>
                <div className="tw-all-tip-body__inner">
                    <div className="tw-all-tip-body-head">
                        <p className="tw-all-tip-owner"><span> Mr.Stanley </span> published a new
                            pick:</p>
                        <label>
                            <span data-bs-toggle="modal" data-bs-target="#reportFormModal"
                                className="tw-report-icon">
                                <img src="assets/images/info-icon.png" alt="info-icon" className="img-fluid" />
                            </span>
                            <span>11 Feb 2022 11:20</span>
                        </label>
                    </div>
                    <div className="tw-tip-detail-content">
                        <div className="tw-tip-detail__inner">
                            <h2 className="tw-tip-title">Sassuolo v Roma</h2>
                            <div className="tw-tip-match-goal-detail">
                                <p className="tw-tip-match-goal-count">Over 1.5 (Match Goals) @
                                    1.667</p>
                                <span className="tw-tip-match-goals">10/10</span>
                                <span className="tw-tip-match-goal-bet">bet365</span>
                            </div>
                            <p className="tw-football-description">
                                <span className="tw-football-icon">
                                    <img src="assets/images/football-icon.png"
                                        alt="football-icon" className="img-fluid" />
                                </span>
                                <span>Football / Livebet / Kick off: 13 Feb 2022, 19:00</span>
                            </p>
                        </div>
                    </div>
                    <p className="tw-tip-text">At vero eos et accusamus et iusto odio dignissimos
                        ducimus qui
                        blanditiis praesentium voluptatum deleniti atque corrupti quos dolores
                        et quas
                        molestias excepturi sint occaecati cupiditate non provident, similique
                        sunt in
                        culpa qui officia deserunt mollitia animi, id est laborum et dolorum
                        fuga. Et harum
                        quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum
                        soluta
                        nobis est eligendi optio cumque nihil impedit quo minus
                        id quod maxime placeat facere possimus, omnis voluptas assumenda est,
                        omnis dolor
                        repellendus. Temporibus autem quibusdam et aut officiis debitis aut </p>
                    <div className="tw-comment-content">
                        <div className="tw-comment-heading">
                            <div className="tw-comment-heading__inner">
                                <button className="btn btn-sm btn-outline-secondary">
                                    <span className="tw-btn-icon">
                                        <img src="assets/images/like-icon.png" alt="like-icon"
                                            className="img-fluid" />
                                    </span>
                                    <span>Like</span>
                                </button>
                                <button className="btn btn-sm btn-outline-secondary">
                                    <span>Translate</span>
                                </button>
                            </div>
                            <div className="tw-comment-heading__inner">
                                <p className="tw-comment-totla-likes">Likes (15)</p>
                                <p className="tw-comment-totla-likes">Comments (0)</p>
                            </div>
                        </div>
                        <div className="tw-comments-list">
                            <div className="tw-comments-item">
                                <div className="tw-commenter-user-img">
                                    <img src="assets/images/tips-profile-img1.png"
                                        alt="tips-profile-img1" className="img-fluid" />
                                </div>
                                <div className="tw-commenter-user-details">
                                    <div className="tw-commenter-details__heading">
                                        <h3 className="tw-commenter-user-name">Mr.Stanley </h3>
                                        <p className="tw-comment-time">11 Feb 2022 11:25</p>
                                    </div>
                                    <p className="tw-commenter-content">Sed ut perspiciatis unde
                                        omnis iste
                                        natus error sit voluptatem accusantium doloremque
                                        laudantium, totam
                                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                        et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam
                                        voluptatem quia voluptas sit aspernatur aut odit aut
                                        fugit, sed quia
                                        consequuntur magni dolores eos qui ratione voluptatem
                                        sequi
                                        nesciunt. </p>
                                </div>
                            </div>
                            <div className="tw-comments-item">
                                <div className="tw-commenter-user-img">
                                    <img src="assets/images/tips-profile-img2.png"
                                        alt="tips-profile-img2" className="img-fluid" />
                                </div>
                                <div className="tw-commenter-user-details">
                                    <div className="tw-commenter-details__heading">
                                        <h3 className="tw-commenter-user-name">OnlyElite </h3>
                                        <p className="tw-comment-time">11 Feb 2022 11:37</p>
                                        <div className="tw-comment-action">
                                            <a href="/"
                                                className="tw-comment-action-link">Delete</a>
                                            <a href="/" className="tw-comment-action-link">Edit</a>
                                        </div>
                                    </div>
                                    <p className="tw-commenter-content">Sed ut perspiciatis unde
                                        omnis iste
                                        natus error sit voluptatem accusantium doloremque
                                        laudantium, totam
                                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                        et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam
                                        voluptatem quia voluptas sit aspernatur aut odit aut
                                        fugit, sed quia
                                        consequuntur magni dolores eos qui ratione voluptatem
                                        sequi
                                        nesciunt. </p>
                                </div>
                            </div>
                            <div className="tw-comments-item">
                                <div className="tw-commenter-user-img">
                                    <img src="assets/images/tips-profile-img1.png"
                                        alt="tips-profile-img1" className="img-fluid" />
                                </div>
                                <div className="tw-commenter-user-details">
                                    <div className="tw-commenter-details__heading">
                                        <h3 className="tw-commenter-user-name">Mr.Stanley </h3>
                                        <p className="tw-comment-time">11 Feb 2022 11:25</p>
                                    </div>
                                    <p className="tw-commenter-content">Sed ut perspiciatis unde
                                        omnis iste
                                        natus error sit voluptatem accusantium doloremque
                                        laudantium, totam
                                        rem aperiam, eaque ipsa quae ab illo inventore veritatis
                                        et quasi
                                        architecto beatae vitae dicta sunt explicabo. Nemo enim
                                        ipsam
                                        voluptatem quia voluptas sit aspernatur aut odit aut
                                        fugit, sed quia
                                        consequuntur magni dolores eos qui ratione voluptatem
                                        sequi
                                        nesciunt. </p>
                                </div>
                            </div>
                        </div>
                        <div className="tw-comment-body">
                            <div className="tw-comment-area">
                                <textarea className="form-control" rows="2"></textarea>
                                <button className="btn btn-sm btn-danger">Comment</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </>
  )
}
